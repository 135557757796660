import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Form } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Navbar from "../../components/navbar/navbar";
import HeaderTitle from "../../components/headerTitle/headerTitle";
import styles from "./styles.module.scss";
import { handleRequest, validateCoordinates } from "../../utils/helpers";
import Button from "../../components/button/button";
import { toast } from "react-toastify";
import InputField from "./inputField";
import TableView from "../../components/table/table";
import SVG from "../../assets/SVG";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

function MapLoction() {
  const [location, setLocation] = useState({
    shop_lat: "",
    shop_lng: "",
    pre_caravan_lat: "",
    pre_caravan_lng: "",
    current_caravan_lat: "",
    current_caravan_lng: "",
    name: "",
    lng: "",
    lat: "",
  });
  const [file, setFile] = useState(null);
  const [selectedTab, setSelectedTab] = useState("location");
  const [error, setError] = useState({});
  const [caravanLocationImages, setCaravanLocationImages] = useState([]);
  const [disableBtn, setDisableBtn] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const inputEl = useRef(null);

  const tableHeadings = ["#", "Image", "Action"];

  const renderTooltip = (text) => (props) => (
    <Tooltip id="tooltip" {...props}>
      {text}
    </Tooltip>
  );

  useEffect(() => {
    getCaravanLocationImages(selectedLocation);
  }, [selectedLocation])

  const getAllLocattions = async () => {
    try {
      const res = await handleRequest("get", "/map/location");
      if (res?.data?.data) {
        const locationsData = res?.data?.data;
        setLocations(locationsData);
      } else {
      }
    } catch (err) {
     console.log("🚀 ~ getAllLocattions ~ err:", err)
     }
  }


  const validation = () => {
    return !validateCoordinates(
      location.name?.toString() || "",
      location.lng?.toString() || "",
      location.lat?.toString() || "",
      setError({})
    );
  };
  const handleChange = (e, index, field) => {
    const newLocations = [...locations];
    newLocations[index][field] = e.target.value;
    setLocations(newLocations);
  };
  const addLocation = () => {
    setLocations([...locations, { lat: '', lng: '' }]);
  };
  const removeLocation = async (location, index) => {
    const newLocations = locations.filter((_, i) => i !== index);
    try {
      setIsSubmitted(true);
      const res = await handleRequest("delete", "/map/location", [{_id: location?._id}]);
      if (res.data.success) {
        toast.success("Location deleted");
        setIsSubmitted(false);
      } else {
      }
    } catch (err) {
    console.log("🚀 ~ updateMapLocation ~ err:", err)
    }
    setLocations(newLocations);

  };

  const updateMapLocation = async () => {
    try {
      setIsSubmitted(true);
      // if (validation()) {
      //   return;
      // } else {
      //   setError({});
      // }
      const updatedLocations = locations.map(location => ({
        ...location,
        images: location.images || [],  // Ensure that the images array is added if not already present
        videos: location.videos || []
      }));
      const res = await handleRequest("post", "/map/location", updatedLocations);
      if (res.data.success) {
        toast.success(res.data.message);
        setIsSubmitted(false);
      } else {
      }
    } catch (err) {
    console.log("🚀 ~ updateMapLocation ~ err:", err)
    }
  };
  useEffect(() => {
    getCaravanLocationImages();
    getAllLocattions();
  }, []);

  const onChange = (e, name) => {
    setLocation({ ...location, [name]: e.target.value });
  };

  useEffect(() => {
    if (isSubmitted && validation()) {
      return;
    } else {
      setError({});
    }
  }, [location]);

  const convertCaravanLocationImages = (data) => {
    let tableData = [];
    data.length > 0 &&
      data.map((item, index) => {
        tableData.push([
          {
            item: index + 1,
          },
          {
            item: (
              <img
                src={`${process.env.REACT_APP_FILE_URL}${item.fileName}`}
                height={80}
                width={110}
                className="mt-1 mb-1"
                alt={"Image"}
              />
            ),
          },
          {
            item: (
              <span>
                <SVG.RemoveIcon
                  className="cursor-pointer"
                  onClick={() => removeCaravanLocationImage(item?._id)}
                />
              </span>
            ),
          },
        ]);
      });
    setCaravanLocationImages(tableData);
  };

  const handlePictureSelected = (event) => {
    var allowedExtensions = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/bmp",
      "image/svg+xml",
    ];
    var uploadPicture = event.target.files[0];
    if (!allowedExtensions.includes(uploadPicture.type)) {
      toast.error("Invalid file type");

      return false;
    }
    setFile(event.target.files[0]);
  };


  const getCaravanLocationImages = async () => {
    try {
      const result = await handleRequest("get",`/caravan-location-images/${selectedLocation}`);
      if (result?.data) {
        convertCaravanLocationImages(result?.data || []);
      }
    } catch (err) {
      console.log(err, "error=>");
    }
  };

  const onCaravanLocationImageSubmit = async () => {
    let errors = {};
    if (!file) {
      errors = { ...errors, file: "Please upload the file" };
    }

    if (Object.keys(errors).length) {
      setError(errors);
      return false;
    }

    setDisableBtn(true);
    var formData = new FormData();
    formData.append("picture", file);
    formData.append("locationId", selectedLocation);    

    const response = await handleRequest(
      "post",
      "/caravan-location-images/create",
      formData
    );
    if (response.data.success) {
      toast.success(response.data.message);
      setFile(null);
      setDisableBtn(false);
      getCaravanLocationImages();
    }
    setDisableBtn(false);
  };

  const removeCaravanLocationImage = async (id) => {
    try {
      const res = await handleRequest("delete", `/caravan-location-images/${id}`);
      if (res.data.success) {
        getCaravanLocationImages();
        toast.success(res.data.message);
      }
    } catch (err) {
    console.log("🚀 ~ removeCaravanLocationImage ~ err:", err)
    }
  };

  useEffect(() => {
    if (!file  && selectedLocation) {
      inputEl.current.value = "";
    }
  }, [file]);
  return (
    <>
      <Navbar />
      <div className="d-flex justify-content-center px-lg-5 px-md-5 px-3 flex-column">
        <HeaderTitle title={"Map Location"} />

        <Tabs
          activeKey={selectedTab}
          className="mb-4 mt-3"
          onSelect={(e) => {
            setSelectedTab(e);
          }}
          justify
        >
          <Tab eventKey="location" title="Map Locations">
            <Row className={styles.mapForm}>
              <Col md={12}>
                <h3 className="mb-3">Locations</h3>
              </Col>
              {locations.map((location, index) => (
                <React.Fragment key={index}>
                  <InputField
                    label="Name*:"
                    name={`shop_namme_${index}`}
                    placeholder="Name"
                    value={location.title}
                    onChange={(e) => handleChange(e, index, 'title')}
                    error={error}
                    type="text"
                  />
                  <InputField
                    label="Latitude*:"
                    name={`shop_lat_${index}`}
                    placeholder="Latitude"
                    value={location.lat}
                    onChange={(e) => handleChange(e, index, 'lat')}
                    error={error}
                    type="number"
                  />
                  <InputField
                    label="Longitude*:"
                    name={`shop_lng_${index}`}
                    placeholder="Longitude"
                    value={location.lng}
                    onChange={(e) => handleChange(e, index, 'lng')}
                    error={error}
                    type="number"
                  />
                  <Col md={3} className="d-flex align-items-center">
                    {/* <OverlayTrigger
                      placement="top" // You can change the placement to top, bottom, left, right
                      overlay={renderTooltip("Update location")}
                    >
                      <SVG.Edit
                        className="cursor-pointer mt-5"
                        onClick={() => updateMapLocation(index)}
                      />
                    </OverlayTrigger> */}
                    <OverlayTrigger
                      placement="top" // You can change the placement to top, bottom, left, right
                      overlay={renderTooltip("Delete location")}
                    >
                      <SVG.RemoveIcon
                        className="cursor-pointer"
                        onClick={() => removeLocation(location, index)}
                      />
                    </OverlayTrigger>
                  </Col>
                </React.Fragment>
              ))}
              <div className="d-flex justify-content-between mb-5">
                <Button
                  text="Save"
                  disabled={disableBtn}
                  handler={() => updateMapLocation()}
                />
                <Button
                  text="Add Anoher Location"
                  disabled={disableBtn}
                  handler={() => addLocation()}
                />
              </div>

            </Row>
          </Tab>
          <Tab eventKey="caravan_image" title="Caravan Images">
            <Row className={styles.mapForm}>
            <Col md={12}>
                <span className="d-flex align-items-center mb-2">
                  <Form.Label className="mb-0" htmlFor="title">
                    Select Category*:
                  </Form.Label>
                </span>
                <Form.Select
                  className="mb-15 form-select"
                  // name="category"
                  // value={product.categoryId}
                  onChange={(e) => {
                    !e.target.value && setSelectedLocation(null);
                    e.target.value && setSelectedLocation(e.target.value);
                  }}
                >
                  <option value="">Select Category</option>

                  {locations?.length > 0 &&
                    locations.map((locat, index) => {
                      return (
                        <option key={index} value={locat._id}>
                          {locat.title}
                        </option>
                      );
                    })}
                </Form.Select>
              </Col>
            </Row>
            {selectedLocation && <div>

            
             <Row className={styles.mapForm}>
              <Col md={6}>
                <span className="d-flex align-items-center mb-2">
                  <Form.Label className="mb-0" htmlFor="URL">
                    Upload File*:
                  </Form.Label>
                  {error?.file && <p className="error mb-0">{error?.message}</p>}
                </span>
                <Form.Control
                  id="file"
                  type="file"
                  className="mb-15 no-arrows"
                  name="file"
                  ref={inputEl}
                  onChange={(e) => {
                    handlePictureSelected(e);
                    // setError({ ...error, file: "" });
                  }}
                />
              </Col>
            </Row>

            <span>
              <Button
                text="save"
                className={"mt-3"}
                handler={onCaravanLocationImageSubmit}
                disabled={disableBtn}
              />
            </span>
            <div className="mt-5">
              <TableView
                tableHeadings={tableHeadings}
                tableData={caravanLocationImages}
              />
            </div>
            </div>}
          </Tab>
        </Tabs>
      </div>
    </>
  );
}

export default MapLoction;
