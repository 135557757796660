// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_titleContainer__XpKVS {\n  background: #ffffff;\n  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);\n  border-radius: 11px;\n  width: 100%;\n  padding: 19px 29px;\n  display: flex;\n  align-items: center;\n  margin: 27px 0;\n}\n.styles_titleContainer__XpKVS p {\n  font-family: \"Mulish\";\n  font-style: normal;\n  font-weight: 700;\n  font-size: 20px;\n  line-height: 20px;\n  color: #585858;\n}", "",{"version":3,"sources":["webpack://./src/components/headerTitle/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,0CAAA;EACA,mBAAA;EACA,WAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,cAAA;AACF;AAAE;EACE,qBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAEJ","sourcesContent":[".titleContainer {\n  background: #ffffff;\n  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);\n  border-radius: 11px;\n  width: 100%;\n  padding: 19px 29px;\n  display: flex;\n  align-items: center;\n  margin: 27px 0;\n  p {\n    font-family: \"Mulish\";\n    font-style: normal;\n    font-weight: 700;\n    font-size: 20px;\n    line-height: 20px;\n    color: #585858;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleContainer": "styles_titleContainer__XpKVS"
};
export default ___CSS_LOADER_EXPORT___;
