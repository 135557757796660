// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_syncBtn__PQRfo {\n  margin-left: auto;\n  display: flex;\n  margin-bottom: 1rem;\n}", "",{"version":3,"sources":["webpack://./src/pages/dashboard/styles.module.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,aAAA;EACA,mBAAA;AACJ","sourcesContent":[".syncBtn {\n    margin-left: auto;\n    display: flex;\n    margin-bottom: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"syncBtn": "styles_syncBtn__PQRfo"
};
export default ___CSS_LOADER_EXPORT___;
