import { ref, child, get, set, push } from "firebase/database";
import { database } from "./firebase";
export const getCollection = () => {
  const dbRef = ref(database);
  get(child(dbRef, `drb-actions`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        console.log(snapshot.val(), "data==>");
      } else {
        console.log("No data available");
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

//////
// export const insertRecord = async ({
//   id,
//   address,
//   type,
//   status,
//   galleryId,
// }) => {
//   try {
//     const newChildRef = push(ref(database));
//     const randomId = newChildRef.key;

//     const recordData = {
//       adress: address,
//       type,
//       status,
//       galleryId: "alula",
//     };
//     const updates = {};
//     updates[randomId] = recordData;

//     await set(ref(database, `drb-actions/${randomId}`), updates);
//     return true;
//   } catch (err) {
//     return false;
//   }
// };
export const insertRecord = async ({
  id,
  address,
  type,
  status,
  galleryId,
}) => {
  try {
    const drbActionsRef = ref(database, 'drb-actions');
    const snapshot = await get(drbActionsRef);
    const recordsArray = snapshot.exists() ? snapshot.val() : [];
    
    const newRecord = {
      adress:address,
      type,
      status,
      galleryId,
    };
    
    recordsArray.push(newRecord);
    
    await set(drbActionsRef, recordsArray);

    return true;
  } catch (err) {
    return false;
  }
};






