import Dashbaord from "../pages/dashboard/dashboard";
import NFTs from "../pages/nfts/nfts";
import Support from "../pages/support/support";
import LoginLayout from "../pages/layouts/loginLayout";
import SigninLayout from "../pages/layouts/SigninLayout";
import EnvironmentDetail from "../pages/environmentDetail/environmentDetail";
import MapLocation from "../pages/MapLocation";
import Signin from "../pages/signin";
import Environment from "../pages/environment";
import OrdersHistory from "../pages/ordersHistory";
import Wallet from "../pages/Wallet";
import FeaturedProducts from "../pages/featuredProducts";
import ProductCategory from "../pages/productCategory";
import HomePageContent from "../pages/homePageContent";
import GiftCategory from "../pages/giftCategory";
import Artist from "../pages/artist";
import UserManagement from "../pages/userManagement";
import Error from "../pages/error";

export const routes = [
  {
    path: "/",
    access: false,
    exact: true,
    title: "dashboard",
    layout: LoginLayout,
    component: Dashbaord,
    role: ["admin"]
  },
  {
    path: "/map-location",
    access: false,
    exact: true,
    title: "dashboard",
    layout: LoginLayout,
    component: MapLocation,
    role: ["admin"]
  },
  {
    path: "/nfts",
    access: false,
    exact: true,
    title: "nfts",
    layout: LoginLayout,
    component: NFTs,
    role: ["admin"]
  },
  {
    path: "/support",
    access: false,
    exact: true,
    title: "support",
    layout: LoginLayout,
    component: Support,
    role: ["admin"]
  },
  {
    path: "/environment-detail/:id",
    access: false,
    exact: true,
    title: "EnvironmentDetail",
    layout: LoginLayout,
    component: EnvironmentDetail,
    role: ["admin"]
  },
  {
    path: "/environment",
    access: false,
    exact: true,
    title: "Signin",
    layout: LoginLayout,
    component: Environment,
    role: ["admin"]
  },
  {
    path: "/content",
    access: false,
    exact: true,
    title: "Content",
    layout: LoginLayout,
    component: HomePageContent,
    role: ["admin"]
  },
  {
    path: "/featured-products",
    access: false,
    exact: true,
    title: "Signin",
    layout: LoginLayout,
    component: FeaturedProducts,
    role: ["admin"]
  },
  {
    path: "/souvenirs-categories",
    access: false,
    exact: true,
    title: "Signin",
    layout: LoginLayout,
    component: ProductCategory,
    role: ["admin"]
  },
  {
    path: "/gift-souvenirs",
    access: false,
    exact: true,
    title: "Signin",
    layout: LoginLayout,
    component: GiftCategory,
    role: ["admin"]
  },
  {
    path: "/orders",
    access: false,
    exact: true,
    title: "Signin",
    layout: LoginLayout,
    component: OrdersHistory,
    role: ["admin", "shipping_company"]
  },
  {
    path: "/wallet",
    access: false,
    exact: true,
    title: "Signin",
    layout: LoginLayout,
    component: Wallet,
    role: ["admin"]
  },
  {
    path: "/artist",
    access: false,
    exact: true,
    title: "Signin",
    layout: LoginLayout,
    component: Artist,
    role: ["admin"]
  },
  {
    path: "/user-management",
    access: false,
    exact: true,
    title: "Signin",
    layout: LoginLayout,
    component: UserManagement,
    role: ["admin"]
  },
  {
    path: "/error",
    access: false,
    exact: true,
    title: "Signin",
    layout: LoginLayout,
    component: Error,
    role: ["admin", "shipping_company"]
  },
  {
    path: "/sign-in",
    access: true,
    exact: true,
    title: "Signin",
    layout: SigninLayout,
    component: Signin,
    role: []
  },
];
