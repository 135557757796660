import React, { useState, useEffect } from "react";
import Navbar from "../../components/navbar/navbar";
import { handleRequest } from "../../utils/helpers";
import { toast } from "react-toastify";
import SVG from "../../assets/SVG";
import TableView from "../../components/table/table";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ProductForm from "./productForm";
import HeaderTitle from "../../components/headerTitle/headerTitle";

function ProductCategory() {
  const initialProductValue = {
    title: "",
    categoryId: "",
    file: null,
  }
  const [product, setProduct] = useState(initialProductValue);
  const [error, setError] = useState(null);
  const [disableBtn, setDisableBtn] = useState(false);
  const [data, setData] = useState(null);
  const [products, setProducts] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [selectedTab, setSelectedTab] = useState('category')
  const tableHeadings = ["#", "Title", "Category", "Image", "Remove"];

  const handlePictureSelected = (event) => {
    const MAX_FILE_SIZE = 5120 // 5MB
    var allowedExtensions = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/bmp",
      "image/svg+xml"
    ];
    var uploadPicture = event.target.files[0];
    const fileSizeKiloBytes = uploadPicture?.size / 1024;

    if (!allowedExtensions.includes(uploadPicture.type)) {
      toast.error("Invalid file type");
      return false;
    }
    if(fileSizeKiloBytes > MAX_FILE_SIZE) {
      toast.error("Please upload a file smaller than 5 MB");
      return false
    }
    setProduct({ ...product, file: event.target.files[0] });
  };

  const converToTableData = (data) => {
    let tableData = [];
    data.length > 0 && data.map((item, index) => {
      tableData.push([
        {
          item: index + 1,
        },
        {
          item: item?.title || "N/A",
        },
        {
          item: categoryName(item?.categoryId) || "N/A",
        },
        {
          item: (
            <img
              src={`${process.env.REACT_APP_FILE_URL}${item.fileName}`}
              height={80}
              width={110}
              className="mt-1 mb-1"
              alt={item?.title}
            />
          ),
        },
        {
          item: (
            <span>
              <SVG.RemoveIcon
                className="cursor-pointer"
                onClick={() => removeProductCategory(item?._id)}
              />
            </span>
          ),
        },
      ]);
    });
    setProducts(tableData);
  };

  const validation = () => {
    let errors = {};
    if (!product.title) {
      errors = { ...errors, title: "This field is required" };
    }
    if (!product.categoryId) {
      errors = { ...errors, categoryId: "This field is required" };
    }
    if (!product.file) {
      errors = { ...errors, file: "Please upload the file" };
    }

    if (Object.keys(errors).length) {
      setError(errors);
      return false;
    }
    return true;
  };

  const categoryName = (categoryId) => {
    const category =
      categoriesList?.length > 0 &&
      categoriesList.find(
        (category) => category.CategoriesCategory.id === categoryId
      );
    return category?.CategoriesCategory?.name || "N/A";
  };
  const onSubmit = async () => {
    if (!validation()) {
      return false;
    }
    setDisableBtn(true);
    const { title, file, categoryId } = product;
    var formData = new FormData();
    formData.append("title", title);
    formData.append("picture", file);
    formData.append("categoryId", categoryId);
    if(selectedTab === "banner"){
      formData.append("isBanner", true);
    }

    const response = await handleRequest(
      "post",
      "/product-category/add",
      formData
    );
    if (response.data.success) {
      getProductCatgories();
      toast.success(response.data.message);
      setDisableBtn(false);
      // const file = document.querySelector("#file");
      // file.value = "";
      setProduct({ title: "", categoryId: "", file: null });
      
    }
    setDisableBtn(false);
  };

  useEffect(() => {
    getCategories();
  }, []);

  const setTableData = (selectedTab, productCategoris) => {
    if(productCategoris.length > 0) {
    const filterResult = filterData(productCategoris)
        converToTableData(filterResult);
    }
  }

  const filterData = (productCategoris) => {
    if(selectedTab === "category"){
      productCategoris = productCategoris.filter(item => !item?.isBanner)
    } else {
      productCategoris = productCategoris.filter(item => item?.isBanner)
    }
    // setSelectedTab(tab)
    return productCategoris;
  }
  const getProductCatgories = async () => {
    try {
      const result = await handleRequest("get", "/product-category");
      if (result?.data?.length > 0) {

        let productCategoris = result.data;
        setData(productCategoris);
        setTableData(selectedTab, productCategoris)
      }
    } catch (err) {
      console.log(err, "error=>");
    }
  };

  const getCategories = async () => {
    try {
      const result = await handleRequest("get", "/categories");
      if (result?.data?.data?.length > 0) {
        setCategoriesList(result.data.data);
      }
    } catch (err) {
      console.log(err, "error=>");
    }
  };

  useEffect(() => {
    getProductCatgories();
  }, [categoriesList]);

  const removeProductCategory = async (id) => {
    try {
      const res = await handleRequest("delete", `/product-category/${id}`);
      if (res.data.success) {
        getProductCatgories();
        toast.success(res.data.message);
      }
    } catch (err) {}
  };

  useEffect(() => {
    data && setTableData(selectedTab, data)
  },[selectedTab])

  return (
    <>
      <Navbar />
      <div className="d-flex justify-content-center px-lg-5 px-md-5 px-3 flex-column">
      <HeaderTitle title={`Souvenir ${selectedTab === 'category' ? 'Category' : 'Banner'}`} />
        <Tabs activeKey={selectedTab} className="mb-4 mt-3" onSelect={(e) => {
          // setTableData(e, data)
          setSelectedTab(e);
          setDisableBtn(false);
          setProduct(initialProductValue)
          }} justify
>
          <Tab eventKey="category" title="Souvenir Category">
            <ProductForm product={product} error={error} setProduct={setProduct} setError={setError} handlePictureSelected={e => handlePictureSelected(e)} onSubmit={onSubmit} disableBtn={disableBtn} categoriesList={categoriesList} file={product.file} />
            <div className="mt-5">
              <TableView tableHeadings={tableHeadings} tableData={products} />
            </div>
          </Tab>
          <Tab eventKey="banner" title="Souvenir Banner">
          <ProductForm product={product} error={error} setProduct={setProduct} setError={setError} handlePictureSelected={e => handlePictureSelected(e)} onSubmit={onSubmit} disableBtn={disableBtn} categoriesList={categoriesList} file={product.file} />
            <div className="mt-5">
              <TableView tableHeadings={tableHeadings} tableData={products} />
            </div>
          </Tab>
        </Tabs>
      </div>
    </>
  );
}

export default ProductCategory;
