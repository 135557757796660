import TableView from "../table/table";

const Environments = ({ galleries }) => {
  const tableHeadings = ["Environment name", "Live users", "Hide/Activate", "Action", "Remove"];

  return (
    <div className="mt-27">
      <TableView tableHeadings={tableHeadings} tableData={galleries} />
    </div>
  );
};
export default Environments;
