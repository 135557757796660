// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_productForm__3WpUd .form-label {\n  font-family: \"Mulish\";\n  font-style: normal;\n  font-weight: 500;\n  font-size: 16px;\n  line-height: 24px;\n  color: #444444;\n}\n.styles_productForm__3WpUd .form-control {\n  padding: 12px 20px;\n  background: rgba(251, 252, 253, 0.94);\n  border: 1px solid #d4cdcd;\n  border-radius: 10px;\n}\n.styles_productForm__3WpUd .form-select {\n  padding: 12px 20px;\n  border: 1px solid #d4cdcd;\n  border-radius: 10px;\n}\n.styles_productForm__3WpUd .error {\n  color: red;\n  font-size: 12px;\n  margin-bottom: 0.5rem;\n}", "",{"version":3,"sources":["webpack://./src/components/productForm/styles.module.scss"],"names":[],"mappings":"AAEM;EACE,qBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AADR;AAGM;EACE,kBAAA;EACA,qCAAA;EACA,yBAAA;EACA,mBAAA;AADR;AAGM;EACE,kBAAA;EACA,yBAAA;EACA,mBAAA;AADR;AAGM;EACE,UAAA;EACA,eAAA;EACA,qBAAA;AADR","sourcesContent":[".productForm {\n    :global {\n      .form-label {\n        font-family: \"Mulish\";\n        font-style: normal;\n        font-weight: 500;\n        font-size: 16px;\n        line-height: 24px;\n        color: #444444;\n      }\n      .form-control {\n        padding: 12px 20px;\n        background: rgba(251, 252, 253, 0.94);\n        border: 1px solid #d4cdcd;\n        border-radius: 10px;\n      }\n      .form-select {\n        padding: 12px 20px;\n        border: 1px solid #d4cdcd;\n        border-radius: 10px;\n      }\n      .error{\n        color: red;\n        font-size: 12px;\n        margin-bottom: 0.5rem;\n  \n      }\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"productForm": "styles_productForm__3WpUd"
};
export default ___CSS_LOADER_EXPORT___;
