import React, { useState, useEffect } from "react";
import Navbar from "../../components/navbar/navbar";
import { handleRequest } from "../../utils/helpers";
import { toast } from "react-toastify";
import SVG from "../../assets/SVG";
import TableView from "../../components/table/table";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ProductForm from "../../components/productForm";
import HeaderTitle from "../../components/headerTitle/headerTitle";

function GiftSouvenirs() {
  const initialProductValue = {
    title: "",
    categoryId: "",
    file: null,
    price: "",
  };
  const [product, setProduct] = useState(initialProductValue);
  const [error, setError] = useState(null);
  const [disableBtn, setDisableBtn] = useState(false);
  const [data, setData] = useState(null);
  const [products, setProducts] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [selectedTab, setSelectedTab] = useState("category");
  const [giftBoxesData, setGiftBoxesData] = useState([])
  const [companyLogo, setCompanyLogo] = useState([])
  const tableHeadings = ["#", "Title", "Category", "Image", "Action"];
  const giftTableHeadings = ["#", "Image", "Price", "Action"];
  const logoTableHeadings = ["#", "Logo", "Action"];

  const handlePictureSelected = (event) => {
    const MAX_FILE_SIZE = 5120; // 5MB
    var allowedExtensions = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/bmp",
      "image/svg+xml"
    ];
    
    var uploadPicture = event.target.files[0];
    const fileSizeKiloBytes = uploadPicture?.size / 1024;

    if (!allowedExtensions.includes(uploadPicture.type)) {
      toast.error("Invalid file type");
      return false;
    }
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      toast.error("Please upload a file smaller than 5 MB");
      return false;
    }
    setProduct({ ...product, file: event.target.files[0] });
  };

  const converToTableData = (data) => {
    let tableData = [];
    data.length > 0 &&
      data.map((item, index) => {
        tableData.push([
          {
            item: index + 1,
          },
          {
            item: item?.title || "N/A",
          },
          {
            item: categoryName(item?.categoryId) || "N/A",
          },
          {
            item: (
              <img
                src={`${process.env.REACT_APP_FILE_URL}${item.fileName}`}
                height={80}
                width={110}
                className="mt-1 mb-1"
                alt={item?.title}
              />
            ),
          },
          {
            item: (
              <span>
                <SVG.RemoveIcon
                  className="cursor-pointer"
                  onClick={() => removeProductCategory(item?._id)}
                />
              </span>
            ),
          },
        ]);
      });
    setProducts(tableData);
  };


  const convertGiftBoxTableData = (data) => {
    let tableData = [];
    data.length > 0 &&
      data.map((item, index) => {
        tableData.push([
          {
            item: index + 1,
          },
          {
            item: (
              <img
                src={`${process.env.REACT_APP_FILE_URL}${item.fileName}`}
                height={80}
                width={110}
                className="mt-1 mb-1"
                alt={"Gift Box"}
              />
            ),
          },
          {
            item: <b style={{color: "#4F489E"}}>SR {parseInt(item?.price).toFixed(2)}</b> || "N/A",
          },
          {
            item: (
              <span>
                <SVG.RemoveIcon
                  className="cursor-pointer"
                  onClick={() => removeGiftPackingBox(item?._id)}
                />
              </span>
            ),
          },
        ]);
      });
    setGiftBoxesData(tableData);
  };

  const convertCompanyLogoTableData = (data) => {
    let tableData = [];
    data.length > 0 &&
      data.map((item, index) => {
        tableData.push([
          {
            item: index + 1,
          },
          {
            item: (
              <img
                src={`${process.env.REACT_APP_FILE_URL}${item.fileName}`}
                height={80}
                width={110}
                className="mt-1 mb-1"
                alt={"Gift Box"}
              />
            ),
          },
          {
            item: (
              <span>
                <SVG.RemoveIcon
                  className="cursor-pointer"
                  onClick={() => removeLogo(item?._id)}
                />
              </span>
            ),
          },
        ]);
      });
      setCompanyLogo(tableData);
  };


  const validation = () => {
    let errors = {};
    if (!product.title) {
      errors = { ...errors, title: "This field is required" };
    }
    if (!product.categoryId) {
      errors = { ...errors, categoryId: "This field is required" };
    }
    if (!product.file) {
      errors = { ...errors, file: "Please upload the file" };
    }

    if (Object.keys(errors).length) {
      setError(errors);
      return false;
    }
    return true;
  };

  const categoryName = (categoryId) => {
    const category =
      categoriesList?.length > 0 &&
      categoriesList.find(
        (category) => category.CategoriesCategory.id === categoryId
      );
    return category?.CategoriesCategory?.name || "N/A";
  };
  const onSubmit = async () => {
    if (!validation()) {
      return false;
    }
    setDisableBtn(true);
    const { title, file, categoryId } = product;
    var formData = new FormData();
    formData.append("title", title);
    formData.append("picture", file);
    formData.append("categoryId", categoryId);
    if (selectedTab === "banner") {
      formData.append("isBanner", true);
    }

    const response = await handleRequest(
      "post",
      "/gift-category/add",
      formData
    );
    if (response.data.success) {
      getProductCatgories();
      toast.success(response.data.message);
      setDisableBtn(false);
      // const file = document.querySelector("#file");
      // file.value = "";
      setProduct({ title: "", categoryId: "", file: null });
    }
    setDisableBtn(false);
  };


  const onGiftBoxSubmit = async () => {
    const priceRegex = /^\d+([.,]\d{0,2})?$/;

    let errors={}
    if (!product.price) {
      errors = { ...errors, price: "This field is required" };
    }

    if(!priceRegex.test(product.price)){
      errors = { ...errors, price: "Please enter the number" };
    }

    if (!product.file) {
      errors = { ...errors, file: "Please upload the file" };
    }

    if (Object.keys(errors).length) {
      setError(errors);
      return false;
    }

    setDisableBtn(true);
    var formData = new FormData();
    formData.append("price", product.price);
    formData.append("picture", product.file);

    const response = await handleRequest(
      "post",
      "/gift-category/add-gift-boxes",
      formData
    );
    if (response.data.success) {
      toast.success(response.data.message);
      setDisableBtn(false);
      getGiftPackingBoxes();
      setProduct({ price: "", file: null });
    }
    setDisableBtn(false);
  };

  const onCompanyLogoSubmit = async () => {
    let errors = {};
    if (!product.file) {
      errors = { ...errors, file: "Please upload the file" };
    }

    if (Object.keys(errors).length) {
      setError(errors);
      return false;
    }

    setDisableBtn(true);
    var formData = new FormData();
    formData.append("picture", product.file);

    const response = await handleRequest(
      "post",
      "/logo/create",
      formData
    );
    if (response.data.success) {
      toast.success(response.data.message);
      setDisableBtn(false);
      getCompanyLogo();
      setProduct({ price: "", file: null });
    }
    setDisableBtn(false);
  };

  useEffect(() => {
    getCategories();
    getGiftPackingBoxes();
    getCompanyLogo();
  }, []);

  const setTableData = (selectedTab, productCategoris) => {
    if (productCategoris.length > 0) {
      const filterResult = filterData(productCategoris);
      converToTableData(filterResult);
    }
  };

  const filterData = (productCategoris) => {
    if (selectedTab === "category") {
      productCategoris = productCategoris.filter((item) => !item?.isBanner);
    } else {
      productCategoris = productCategoris.filter((item) => item?.isBanner);
    }
    // setSelectedTab(tab)
    return productCategoris;
  };

  const getProductCatgories = async () => {
    try {
      const result = await handleRequest("get", "/gift-category");
      if (result?.data?.length > 0) {
        let productCategoris = result.data;
        setData(productCategoris);
        setTableData(selectedTab, productCategoris);
      }
    } catch (err) {
      console.log(err, "error=>");
    }
  };

  const getCompanyLogo = async () => {
    try {
      const result = await handleRequest("get", "/logo");
      if (result?.data?.length > 0) {
        convertCompanyLogoTableData(result.data);
      }
    } catch (err) {
      console.log(err, "error=>");
    }
  };

  const getGiftPackingBoxes = async () => {
    try {
      const result = await handleRequest("get", "/gift-category/gift-boxes");
      if (result?.data?.length > 0) {

        convertGiftBoxTableData(result.data);
      }
    } catch (err) {
      console.log(err, "error=>");
    }
  };


  const getCategories = async () => {
    try {
      const result = await handleRequest("get", "/categories");
      if (result?.data?.data?.length > 0) {
        setCategoriesList(result.data.data);
      }
    } catch (err) {
      console.log(err, "error=>");
    }
  };

  useEffect(() => {
    getProductCatgories();
  }, [categoriesList]);

  const removeProductCategory = async (id) => {
    try {
      const res = await handleRequest("delete", `/gift-category/${id}`);
      if (res.data.success) {
        getProductCatgories();
        toast.success(res.data.message);
      }
    } catch (err) {}
  };

  const removeGiftPackingBox = async (id) => {
    try {
      const res = await handleRequest("delete", `/gift-category/gift-box/${id}`);
      if (res.data.success) {
        getGiftPackingBoxes();
        toast.success(res.data.message);
      }
    } catch (err) {}
  };

  const removeLogo = async (id) => {
    try {
      const res = await handleRequest("delete", `/logo/${id}`);
      if (res.data.success) {
        getCompanyLogo();
        toast.success(res.data.message);
      }
    } catch (err) {}
  };

  useEffect(() => {
    data && setTableData(selectedTab, data);
  }, [selectedTab]);

  return (
    <>
      <Navbar />
      <div className="d-flex justify-content-center px-lg-5 px-md-5 px-3 flex-column">
        <HeaderTitle
          title={`Souvenir Gift ${
            selectedTab === "category" ? "Category" : "Banner"
          }`}
        />
        <Tabs
          activeKey={selectedTab}
          className="mb-4 mt-3"
          onSelect={(e) => {
            // setTableData(e, data)
            setSelectedTab(e);
            setError(null)
            setDisableBtn(false);
            setProduct(initialProductValue);
          }}
          justify
        >
          <Tab eventKey="category" title="Gift Souvenir">
            <ProductForm
              product={product}
              error={error}
              setProduct={setProduct}
              setError={setError}
              handlePictureSelected={(e) => handlePictureSelected(e)}
              onSubmit={onSubmit}
              disableBtn={disableBtn}
              categoriesList={categoriesList}
              file={product.file}
              eventKey="category"
            />
            <div className="mt-5">
              <TableView tableHeadings={tableHeadings} tableData={products} />
            </div>
          </Tab>
          <Tab eventKey="banner" title="Gift Banner">
            <ProductForm
              product={product}
              error={error}
              setProduct={setProduct}
              setError={setError}
              handlePictureSelected={(e) => handlePictureSelected(e)}
              onSubmit={onSubmit}
              disableBtn={disableBtn}
              categoriesList={categoriesList}
              file={product.file}
              eventKey="banner"
            />
            <div className="mt-5">
              <TableView tableHeadings={tableHeadings} tableData={products} />
            </div>
          </Tab>
          <Tab eventKey="packing" title="Gift Packing Box">
            <ProductForm
              fileTitle="Gift Packing Image*"
              product={product}
              error={error}
              setProduct={setProduct}
              setError={setError}
              handlePictureSelected={(e) => handlePictureSelected(e)}
              onSubmit={onGiftBoxSubmit}
              disableBtn={disableBtn}
              categoriesList={categoriesList}
              file={product.file}
              eventKey="packing"
            />
            <div className="mt-5">
              <TableView tableHeadings={giftTableHeadings} tableData={giftBoxesData} />
            </div>
          </Tab>
          <Tab eventKey="logo" title="Companies Logo">
            <ProductForm
              fileTitle="Logo*"
              product={product}
              error={error}
              setProduct={setProduct}
              setError={setError}
              handlePictureSelected={(e) => handlePictureSelected(e)}
              onSubmit={onCompanyLogoSubmit}
              disableBtn={disableBtn}
              file={product.file}
              eventKey="logo"
            />
            <div className="mt-5">
              <TableView tableHeadings={logoTableHeadings} tableData={companyLogo} />
            </div>
          </Tab>
        </Tabs>
      </div>
    </>
  );
}

export default GiftSouvenirs;
