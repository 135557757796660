import React, { useEffect, useRef } from "react";
import { Row, Col, Form } from "react-bootstrap";
import styles from "./styles.module.scss";
import Button from "../button/button";

export default function ProductForm(props) {
  const {
    product,
    error,
    setProduct,
    setError,
    handlePictureSelected,
    onSubmit,
    disableBtn,
    categoriesList,
    file,
    eventKey,
    fileTitle
  } = props;
  const inputEl = useRef(null);
  useEffect(() => {
    if (!file) {
      inputEl.current.value = "";
    }
  }, [disableBtn, file]);
  return (
    <>
      <Row className={styles.productForm}>
        {eventKey === "packing" ? (
          <Col md={6}>
            <span className="d-flex align-items-center mb-2">
              <Form.Label className="mb-0" htmlFor="title">
                Price*:
              </Form.Label>
              {error?.price && <p className="error mb-0">{error?.price}</p>}
            </span>
            <Form.Control
              id="price"
              type="text"
              placeholder="Price"
              className="mb-15 no-arrows"
              name="price"
              value={product.price}
              onChange={(e) => {
                setProduct({ ...product, price: e.target.value });
                setError({ ...error, price: "" });
              }}
            />
          </Col>
        ) : (
          eventKey !== "logo" && (
          <>
            <Col md={6}>
              <span className="d-flex align-items-center mb-2">
                <Form.Label className="mb-0" htmlFor="title">
                  Title*:
                </Form.Label>
                {error?.title && <p className="error mb-0">{error?.title}</p>}
              </span>
              <Form.Control
                id="title"
                type="text"
                placeholder="Title"
                className="mb-15 no-arrows"
                name="title"
                value={product.title}
                onChange={(e) => {
                  setProduct({ ...product, title: e.target.value });
                  setError({ ...error, title: "" });
                }}
              />
            </Col>
            <Col md={6}>
              <span className="d-flex align-items-center mb-2">
                <Form.Label className="mb-0" htmlFor="title">
                  Select Category*:
                </Form.Label>
                {error?.categoryId && (
                  <p className="error mb-0">{error?.categoryId}</p>
                )}
              </span>

              <Form.Select
                className="mb-15 form-select"
                name="category"
                value={product.categoryId}
                onChange={(e) => {
                  setProduct({ ...product, categoryId: e.target.value });
                  setError({ ...error, category: "" });
                }}
              >
                <option value="">Select Category</option>

                {categoriesList?.length > 0 &&
                  categoriesList.map((category, index) => {
                    return (
                      <option
                        key={index}
                        value={category.CategoriesCategory.id}
                      >
                        {category.CategoriesCategory.name}
                      </option>
                    );
                  })}
              </Form.Select>
            </Col>
          </>)
        )}
        <Col md={6}>
          <span className="d-flex align-items-center mb-2">
            <Form.Label className="mb-0" htmlFor="URL">
              {fileTitle || "Souvenir Image*:"}
            </Form.Label>
            {error?.file && <p className="error mb-0">{error.file}</p>}
          </span>

          <Form.Control
            id="file"
            type="file"
            ref={inputEl}
            className="mb-15 no-arrows"
            name="file"
            onChange={(e) => {
              handlePictureSelected(e);
              setError({ ...error, file: "" });
            }}
          />
        </Col>
      </Row>
      <span>
        <Button
          text="Submit"
          className={"mt-3"}
          handler={onSubmit}
          disabled={disableBtn}
        />
      </span>
    </>
  );
}
