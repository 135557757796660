import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import Navbar from "../../components/navbar/navbar";
import HeaderTitle from "../../components/headerTitle/headerTitle";
import styles from "./styles.module.scss";
import Paper from "@mui/material/Paper";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TablePagination from "@mui/material/TablePagination";
import Box from "@mui/material/Box";
import moment from "moment";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { handleRequest } from "../../utils/helpers";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { toast } from "react-toastify";
import { ENV } from "../../config";
import OrderDetailModal from "./orderDetailModal";

let timer;

const debounce = function (fn, d) {
  if (timer) {
    clearTimeout(timer);
  }

  timer = setTimeout(fn, d);
};

const OrdersHistory = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState([]);
  const [ordersData, setOrdersData] = useState(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderStatus, setOrderStatus] = useState("all");
  const [modalShow, setModalShow] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [search, setSearch] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getOrders(newPage, rowsPerPage, orderStatus);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    getOrders(0, event.target.value, orderStatus);
  };

  const getOrders = async (selectedPage, limit, status, searchVal) => {
    let url = `/order/all?page=${
      selectedPage + 1
    }&limit=${limit}&sortBy=createdAt`;
    if (status !== "all") {
      url += `&status=${status}`;
    }
    if (searchVal) {
      url += `&search=${searchVal}`;
    }
    try {
      setLoading(true);
      const res = await handleRequest("post", url);
      if (res) {
        let data = res.data.data.map((_o) => {
          const productInfo = _o.productInfo.sort((p1, p2) =>
            p1.Product.id < p2.Product.id
              ? 1
              : p1.Product.id > p2.Product.id
              ? -1
              : 0
          );
          const items = _o.items.sort((item1, item2) =>
            item1.productId < item2.productId
              ? 1
              : item1.productId > item2.productId
              ? -1
              : 0
          );
          _o.productInfo = productInfo;
          _o.items = items;
          return _o;
        });
        res.data.data = data;
        setOrdersData(res.data);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrders(page, rowsPerPage, orderStatus, search);
  }, []);

  const toogleOpen = (id) => {
    const index = open.findIndex((_o) => _o === id);
    if (index > -1) {
      open.splice(index, 1);
      setOpen([...open]);
    } else {
      open.push(id);
      setOpen([...open]);
    }
  };

  const updateOrderStatus = async (orderId, status) => {
    try {
      setLoading(true);
      const res = await handleRequest("patch", "/order", { orderId, status });
      setLoading(false);
      if (res.data.success) {
        toast.success(res.data.message);
        getOrders(page, rowsPerPage, orderStatus);
      } else {
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const selectStatus = ({ value, label, onChange }) => {
    return (
      <Select
        labelId="status-select-label"
        id="status-select-label"
        value={value}
        label={label}
        onChange={(event) => onChange(event)}
      >
        {label !== "Status" && <MenuItem value={"all"}>All</MenuItem>}
        <MenuItem value={"ordered"}>Ordered</MenuItem>
        <MenuItem value={"shipped"}>Shipped</MenuItem>
        <MenuItem value={"delivered"}>Delivered</MenuItem>
        <MenuItem value={"cancelled"}>Cancelled</MenuItem>
      </Select>
    );
  };

  const filterOrders = (status) => {
    getOrders(page, rowsPerPage, status);
    setOrderStatus(status);
  };

  const isGiftProduct = (items) => {
    return items.length > 0 && items.some((item) => item.gift_wrap);
  };

  return (
    <div className={"main-wrapper"}>
      <Navbar />
      <div className="d-flex justify-content-center px-lg-5 px-md-5 px-3 flex-column">
        <HeaderTitle title={"Orders History"} />
        <Row className={styles.envForm}>
          <Col md={12} className={styles.filters}>
            <Row>
              <Col md={3}>
                <FormControl fullWidth size="small">
                  <TextField
                    id="outlined-required"
                    label="Search"
                    size="small"
                    onChange={(e) => {
                      const value = e.target.value;
                      setSearch(value);
                      debounce(() => {
                        getOrders(0, rowsPerPage, orderStatus, value);
                      }, 1200);
                    }}
                  />
                </FormControl>
              </Col>
              <Col md={3}>
                <FormControl fullWidth size="small">
                  <InputLabel id="status-select-label">
                    Filter By Status
                  </InputLabel>

                  {selectStatus({
                    value: orderStatus,
                    label: "Filter By Status",
                    onChange: (event) => filterOrders(event.target.value),
                  })}
                </FormControl>
              </Col>
            </Row>
          </Col>
          <Col md={12}>
            <Paper sx={{ width: "100%" }}>
              <TableContainer
                sx={{ maxHeight: "calc(100vh - 310px)", minHeight: "200px" }}
              >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {/* <TableCell /> */}
                      <TableCell align="center">Transaction ID</TableCell>
                      <TableCell style={{ minWidth: 150 }}>
                        Customer Name
                      </TableCell>
                      <TableCell style={{ minWidth: 150 }} align="center">
                        Total Items
                      </TableCell>
                      <TableCell align="center">Payment Status</TableCell>
                      <TableCell align="center">Status</TableCell>
                      <TableCell style={{ minWidth: 210 }} align="center">
                        Created At
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ordersData?.data?.length > 0 ? (
                      ordersData.data.map((order, i) => {
                        return (
                          <TableRow key={i}>
                            <TableCell align="center">
                              {order?.transaction?.ref ? (
                                <button
                                  type="button"
                                  class="btn btn-link"
                                  onClick={() => {
                                    setSelectedOrder(order);
                                    setModalShow(true);
                                  }}
                                >
                                  {order?.transaction?.ref}
                                </button>
                              ) : (
                                "-"
                              )}
                            </TableCell>
                            <TableCell>
                              {`${order.shippingInfo?.first_name} ${order.shippingInfo?.last_name}`}
                            </TableCell>
                            <TableCell align="center">
                              {order.items.length}
                            </TableCell>
                            <TableCell align="center">
                              <Box>
                                <FormControl size="small">
                                  {[
                                    "ordered",
                                    "shipped",
                                    "delivered",
                                    "cancelled",
                                  ].includes(order.status) ? (
                                    <>
                                      <InputLabel id="status-select-label">
                                        Status
                                      </InputLabel>
                                      {selectStatus({
                                        value: order.status,
                                        label: "Status",
                                        onChange: (event) =>
                                          updateOrderStatus(
                                            order._id,
                                            event.target.value
                                          ),
                                      })}
                                    </>
                                  ) : (
                                    <p style={{ textTransform: "capitalize" }}>
                                      {order.status}
                                    </p>
                                  )}
                                </FormControl>
                              </Box>
                            </TableCell>
                            <TableCell align="center">
                              <p style={{ textTransform: "capitalize" }}>
                                {order?.payment_status || "-"}
                              </p>
                            </TableCell>
                            <TableCell align="center">
                              {moment(order.createdAt).format("LLL")}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell>
                          <h3>
                            {!loading ? "Records not found!" : "Loading..."}
                          </h3>
                        </TableCell>
                        <TableCell /> <TableCell />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {ordersData?.data?.length > 0 && (
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={ordersData?.pagination?.totalCount || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </Paper>
          </Col>
        </Row>
        <Backdrop
          sx={{
            color: "#5fbb46",
            marginTop: -25,
            paddingLeft: 35,
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {selectedOrder && modalShow && (
          <OrderDetailModal
            modalShow={modalShow}
            onHide={() => {
              setModalShow(false);
              setSelectedOrder(null);
            }}
            order={selectedOrder}
          />
        )}
      </div>
    </div>
  );
};

export default OrdersHistory;

// <TableRow>
//                               <TableCell
//                                 style={{ paddingBottom: 0, paddingTop: 0 }}
//                                 colSpan={6}
//                               >
//                                 <Collapse
//                                   in={open.includes(order._id)}
//                                   timeout="auto"
//                                   unmountOnExit
//                                 >
//                                   <Box sx={{ margin: 1 }}>
//                                     <Typography
//                                       variant="h6"
//                                       gutterBottom
//                                       component="div"
//                                     >
//                                       Souvenirs
//                                     </Typography>
//                                     <Table
//                                       aria-label="purchases"
//                                       style={{ marginBottom: 30 }}
//                                     >
//                                       <TableHead>
//                                         <TableRow>
//                                           <TableCell>Souvenir Name</TableCell>
//                                           <TableCell align="center">
//                                             Souvenir Image
//                                           </TableCell>
//                                           <TableCell>Qty</TableCell>
//                                           {isGiftProduct(order.items) && (
//                                             <>
//                                               <TableCell>Gift Wrap Image</TableCell>
//                                               <TableCell>
//                                                 Gift Wrap Price
//                                               </TableCell>
//                                             </>
//                                           )}
//                                           <TableCell align="center">
//                                             Unit Amount
//                                           </TableCell>
//                                         </TableRow>
//                                       </TableHead>
//                                       <TableBody>
//                                         {order.productInfo.map(
//                                           (item, index) => (
//                                             <TableRow key={index}>
//                                               <TableCell
//                                                 component="th"
//                                                 scope="row"
//                                               >
//                                                 {item.Product.name}
//                                               </TableCell>
//                                              <TableCell align="center">
//                                                 <img
//                                                   src={
//                                                     item.Product?.ProductImage
//                                                       ?.length > 0
//                                                       ? `https://drbdesignksa.daftra.com/${item.Product?.ProductImage[0].file_full_path}`
//                                                       : item.Product
//                                                           ?.ProductImageS3
//                                                           ?.length > 0 &&
//                                                         item.Product
//                                                           .ProductImageS3[0]
//                                                           .file_full_path
//                                                   }
//                                                   height={60}
//                                                   width={60}
//                                                   alt={item.Product.name}
//                                                 />
//                                               </TableCell>
//                                               <TableCell>
//                                                 {order?.items?.length > 0 ? order.items[index]?.quantity : 1}
//                                               </TableCell>
//                                               {isGiftProduct(order.items) && (
//                                                 <>
//                                                   <TableCell>
//                                                     {order.items[index]?.gift_wrap ?
//                                                     <img
//                                                       src={`${process.env.REACT_APP_FILE_URL}${order.items[index].gift_wrap.fileName}`}
//                                                       height={60}
//                                                       width={60}
//                                                       alt={item.Product.name}
//                                                     /> : "-"}
//                                                   </TableCell>
//                                                   <TableCell>
//                                                     {order.items[index]?.gift_wrap  ?
//                                                       `SR ${parseFloat(order.items[index].gift_wrap.price).toFixed(2)}`
//                                                         : "-"
//                                                     }
//                                                   </TableCell>
//                                                 </>
//                                               )}
//                                               <TableCell align="center">
//                                                 {item.Product.unit_price}
//                                               </TableCell>
//                                             </TableRow>
//                                           )
//                                         )}
//                                       </TableBody>
//                                     </Table>
//                                   </Box>
//                                 </Collapse>
//                               </TableCell>
//                             </TableRow>
