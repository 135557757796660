import "./App.css";
import { useEffect } from "react";
import { routes } from "./routes/index";
import { Routes, Route } from "react-router-dom";
import PrivateRoute from "./routes/privateRoute";
import context from "./context/context";
import { useState } from "react";
import Socket from "./utils/socket";
import { ENV } from "./config";
function App() {
  const [showMenu, setShowMenu] = useState(false);
  const [socket, setSocket] = useState(undefined);
  useEffect(() => {
    if (!socket) {
      const connectedSocket = Socket.connectSocket();
      setSocket(connectedSocket);
    }
    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, [ENV.WEBSOCKET_URL]);
  
  return (
    <context.Provider value={{ showMenu, setShowMenu, socket }}>
      <Routes>
        {routes.map((route, i) => {
          return (
            <Route
              path={route.path}
              key={i}
              element={
                <PrivateRoute
                  access={
                    route.access 
                  }
                  role={route.role}
                  path={route.path}
                >
                  <route.layout>
                    <route.component />
                  </route.layout>
                </PrivateRoute>
              }
            />
          );
        })}
      </Routes>
    </context.Provider>
  );
}

export default App;
