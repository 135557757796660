import { useEffect } from "react";
import { toast } from "react-toastify";
import Web3 from "web3";
import { ENV } from "../config";
import { useNavigate } from "react-router-dom";
export const useListenMetaMaskAccs = () => {
  const navigate = useNavigate();
  useEffect(() => {
    try {
      if (!window?.ethereum) {
        return;
      }

      const accountWasChanged = async (data) => {
        console.log("acc is changed==>");
        // const connectedChainId = await getChainId();
        if (data.length === 0) {
          return toast.error("you are not connected with metamask");
        }

        if (ENV.getUserKeys("loginType")?.loginType === "metamask") {
          toast.info("Your account is changed please login again");
          localStorage.removeItem("encuse");
          navigate("/sign-in");
        }
      };

      window?.ethereum.on("accountsChanged", accountWasChanged);
      window?.ethereum.on("chainChanged", (chainId) => {
        if (Web3.utils.hexToNumber(chainId) !== ENV.chainId) {
          if (ENV.getUserKeys("loginType")?.loginType === "metamask") {
            toast.info("Your chain is changed please login again");
            localStorage.removeItem("encuse");
            navigate("/sign-in");
          }
          //   localStorage.removeItem("encuse");
        }
      });

      return () => {
        // Clean up event listeners
        window?.ethereum.removeListener("accountsChanged", accountWasChanged);
      };
    } catch (err) {
      console.log(err, "error==>");
    }
  }, []);
};
