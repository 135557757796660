import styles from "./styles.module.scss";
import { Table } from "react-bootstrap";
const TableView = ({ tableData, tableHeadings }) => {
  return (
    <div className={styles.tableWrapper}>
      <Table responsive>
        <thead>
          <tr>
            {tableHeadings.map((item, index) => (
              <th
                className={styles.tableHeading}
                key={index}
                style={{ alignItems: "center" }}
              >
                <span className={styles.tableHeadingItem}>{item}</span>
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {tableData &&
            tableData.map((item, i) => (
              <tr key={i+10}>
                {item?.map((el,index) => {
                  return (
                    <td className={styles.tableData} key={index+20}>
                      {el.item}
                    </td>
                  );
                })}
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
};

export default TableView;
