import HeaderTitle from "../../components/headerTitle/headerTitle";
import Stats from "../../components/stats/stats";
import Environments from "../../components/environments/environments";
import Navbar from "../../components/navbar/navbar";
import { useState, useEffect, useRef } from "react";
import { useDownloadExcel } from 'react-export-table-to-excel';
import Button from "../../components/button/button";
import { useNavigate } from "react-router-dom";
import { ENV } from "../../config";
import { handleRequest } from "../../utils/helpers";
import { galleriesImages } from "../../assets/galleries";
import SVG from "../../assets/SVG";
import { toast } from "react-toastify";
import styles from "./styles.module.scss";
import writeXlsxFile from 'write-excel-file'


const Dashbaord = () => {
  const [galleries, setGalleries] = useState(null);
  const [data, setData] = useState(null);
  const [page, setPage] = useState(1);
  const [products, setProducts] = useState([]);
  const [exportBtnDisabled, setExportBtnDisabled] = useState(true)
  const navigate = useNavigate();

  useEffect(() => {
    getAllGalleries();
    if(products.length === 0) {
    getAllProducts();
    }
  }, []);
  const getAllGalleries = async () => {
    try {
      const userId = ENV.getUserKeys("_id")?._id;
      const res = await handleRequest("post", "/galleries", { userId }, { page });
      setData(res.data.data);
      converToTableData(res.data.data);
    } catch (err) {
    }
  };

  const getAllProducts = async () => {
    try {
      const res = await handleRequest("get", "/feature-product/all");
      if(res.data.length > 0) {
      let results = res.data.sort((a, b) => {
        return a.Product.id - b.Product.id
      }) 
      results = [...new Map(results.map(item => [item.Product["id"], item])).values()]
      results = results.filter(({Product}) => Product.ProductImage.length > 0 || Product.ProductImageS3.length > 0 )
      setProducts(results)
      setExportBtnDisabled(false)
    }
    } catch (err) {}
  };

  const activateDeactivate = async (id, isActive) => {
    try {
      const res = await handleRequest("patch", "/galleries/activateDeactivate", {
        id,
        isActive
      });
      if (res.data.success) {
        getAllGalleries();
        toast.success(res.data.message);
      }
    } catch (err) {
    }
  };

  const removeGallery = async (id) => {
    try {
      const res = await handleRequest("delete", `/galleries/${id}`);
      if (res.data.success) {
        getAllGalleries();
        toast.success(res.data.message);
      }
    } catch (err) {
    }
  };

  const syncProducts = async () => {
    try {
      const res = await handleRequest("get", "/feature-product/sync");
      if (res.data.success) {
        toast.success("Products syncing");
      }
    } catch (err) {
    }
  };

  const fbSyncProducts = async () => {
    try {
      const res = await handleRequest("get", "/feature-product/fbsync");
      if (res.data.success) {
        toast.success("FB Products syncing");
      }
    } catch (err) {
    }
  };

  const converToTableData = (data) => {
    let tableData = [];
    data.map((item) => {
      tableData.push([
        {
          item: (
            <span className="d-flex align-items-center">
              <img src={galleriesImages[item?.fileName]} style={{ height: "49px" }} />
              <p className="mx-4">{item.name}</p>
            </span>
          )
        },

        {
          item: (
            <span className="d-flex align-items-center">
              {item?.liveUserId?.users?.length || 0}
            </span>
          )
        },
        {
          item: (
            <span>
              <Button
                text={`${item?.isActive ? "De-Activate" : "Activate"}`}
                handler={() => activateDeactivate(item._id, !item.isActive)}
              />
            </span>
          )
        },
        {
          item: (
            <span>
              <Button text="view" handler={() => navigate(`/environment-detail/${item._id}`)} />
            </span>
          )
        },
        {
          item: (
            <span>
            <SVG.RemoveIcon
              className="cursor-pointer"
              onClick={() => removeGallery(item._id)}
            />
          </span>
          )
        }
      ]);
    });
    setGalleries(tableData);
  };
  const onSearch = (title) => {
    if (!title.trim()) {
      return converToTableData(data);
    }
    const searchedResult = data.filter((item) => {
      return item?.name?.toUpperCase().includes(title?.toUpperCase());
    });
    converToTableData(searchedResult);
  };

  const tableRef = useRef(null);

    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: 'Products',
        sheet: 'Products'
    })

    const downloadProductsExcel = async() => {
      let productsArr = [];

      products.length > 0 && products.map(({Product}) => {
          const obj = {
            id: Product.id,
            title: (Product?.name).toLowerCase() || "-",
            description: (Product?.description).toLowerCase() || "-",
            availability: Product.stock_balance > 0 ? "in stock" : "out of stock",
            condition: "new",
            price: `${Product?.unit_price} SAR`,
            link: `https://drbksa.com/souvenirs/${Product.id}`,
            image_link: Product?.ProductImage?.length > 0 ? `https://drbdesignksa.daftra.com${Product.ProductImage[0].file_full_path}` : Product.ProductImageS3.length > 0 ? Product.ProductImageS3[0].file_full_path : "",
            brand: Product?.brand || "-",
            item_group_id: Product?.ProductCategory?.length > 0 ? Product.ProductCategory[0]?.id : "-"
          }
          productsArr.push(obj);
      })

      const schema = [
        {
          column: 'id',
          type: String,
          value: product => product.id
        },
        {
          column: 'title',
          type: String,
          value: product => product.title
        },
        {
          column: 'description',
          type: String,
          value: product => product.description
        },
        {
          column: 'availability',
          type: String,
          value: product => product.availability
        },
        {
          column: 'condition',
          type: String,
          value: product => product.condition
        },
        {
          column: 'price',
          type: String,
          value: product => product.price
        },

        {
          column: 'link',
          type: String,
          value: product => product.link
        },
        {
          column: 'image_link',
          type: String,
          value: product => product.image_link
        },
        {
          column: 'brand',
          type: String,
          value: product => product.brand
        },
        {
          column: "item_group_id",
          type: String,
          value: product => product.item_group_id
        }
      ]
      
      const HEADER_ROW = [
        {
          value: 'id',
          fontWeight: 'bold'
        },
        {
          value: 'title',
          fontWeight: 'bold'
        },
        {
          value: 'description',
          fontWeight: 'bold'
        },
        {
          value: 'availability',
          fontWeight: 'bold'
        },
        {
          value: 'condition',
          fontWeight: 'bold'
        },
        {
          value: 'price',
          fontWeight: 'bold'
        },
        {
          value: 'link',
          fontWeight: 'bold'
        },
        {
          value: 'image_link',
          fontWeight: 'bold'
        },
        {
          value: 'brand',
          fontWeight: 'bold'
        },
        {
          value: 'item_group_id',
          fontWeight: 'bold'
        }
      ]
      
      
      const data = [
        HEADER_ROW,
        productsArr
      ]
      await writeXlsxFile(productsArr, {
        schema,
        fileName: 'products.xlsx'
      })
    }
  return (
    <>
      <Navbar onSearch={onSearch} />
      <div className="d-flex px-lg-5 px-md-5 px-3 flex-column">
        <HeaderTitle title={"Dashboard"} />
        <div className="d-flex w-100 justify-content-end">
          <div>
          <Button text="Sync Products" className={styles.syncBtn} handler={() => syncProducts()} /></div>
          <div>
          <Button text="Export Products" className={`${styles.syncBtn} ms-3`} handler={downloadProductsExcel} disabled={exportBtnDisabled}/>
          </div>
        </div>
        <Stats />
        <Environments galleries={galleries} />
        {/* <table  ref={tableRef} style={{display: "none"}}>
                 <tbody>
                    <tr>
                        <th>id</th>
                        <th>title</th>
                        <th>description</th>
                        <th>availability</th>
                        <th>condition</th>
                        <th>price</th>
                        <th>link</th>
                        <th>image_link</th>
                        <th>brand</th>
                        <th>item_group_id</th>
                    </tr>
                    {products.length>0 && products.map((product, i) => (
                    <tr key={i}>
                        <td>{product.Product.id}</td>
                        <td>{(product?.Product?.name).toLowerCase() || "-"}</td>
                        <td>{(product?.Product?.description).toLowerCase() || "-"}</td>
                        <td>{product.Product.stock_balance > 0 ? "in stock" : "out of stock"}</td>
                        <td>{"new"}</td>
                        <td>{`${product?.Product?.unit_price} SAR`}</td>
                        <td>{`https://drbksa.com/souvenirs/${product.Product.id}`}</td>
                        <td>{product?.Product?.ProductImage?.length > 0 ? `https://drbdesignksa.daftra.com${product.Product.ProductImage[0].file_full_path}` : product.Product.ProductImageS3.length > 0 ? product.Product.ProductImageS3[0].file_full_path : ""}</td>
                        <td>{product?.Product?.brand || "-"}</td>
                        <td>{product?.Product?.ProductCategory?.length > 0 ? product.Product.ProductCategory[0]?.id : "-"}</td>
                    </tr>
                    ))}
                  </tbody>
                </table> */}
      </div>
    </>
  );
};

export default Dashbaord;
