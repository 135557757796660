// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_tableWrapper__4mzP9 {\n  background-color: #fff;\n  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);\n  border-radius: 10px;\n  padding-top: 30px;\n}\n.styles_tableWrapper__4mzP9 th,\n.styles_tableWrapper__4mzP9 td {\n  padding: 11px 23px;\n  vertical-align: middle;\n  border-bottom-width: 0;\n}\n.styles_tableWrapper__4mzP9 tbody tr {\n  border-bottom: 1px solid rgba(0, 0, 0, 0.1);\n  height: 60px;\n}\n.styles_tableWrapper__4mzP9 .styles_tableHeading__\\+6MOv .styles_tableHeadingItem__nxXvn {\n  color: #000000;\n  opacity: 0.4;\n}", "",{"version":3,"sources":["webpack://./src/components/table/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,0CAAA;EACA,mBAAA;EACA,iBAAA;AACF;AAAE;;EAEE,kBAAA;EACA,sBAAA;EACA,sBAAA;AAEJ;AAEI;EACE,2CAAA;EACA,YAAA;AAAN;AAQI;EACE,cAAA;EACA,YAAA;AANN","sourcesContent":[".tableWrapper {\n  background-color: #fff;\n  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);\n  border-radius: 10px;\n  padding-top: 30px;\n  th,\n  td {\n    padding: 11px 23px;\n    vertical-align: middle;\n    border-bottom-width: 0;\n  }\n\n  tbody {\n    tr {\n      border-bottom: 1px solid rgba(0, 0, 0, 0.1);\n      height: 60px;\n      &:last-child {\n        // border-bottom: 0px;\n      }\n    }\n  }\n\n  .tableHeading {\n    .tableHeadingItem {\n      color: #000000;\n      opacity: 0.4;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableWrapper": "styles_tableWrapper__4mzP9",
	"tableHeading": "styles_tableHeading__+6MOv",
	"tableHeadingItem": "styles_tableHeadingItem__nxXvn"
};
export default ___CSS_LOADER_EXPORT___;
