import { useEffect, useState } from "react";
import { handleRequest } from "../../utils/helpers";
import TableView from "../../components/table/table";
import Button from "../../components/button/button";
import HeaderTitle from "../../components/headerTitle/headerTitle";
import { toast } from "react-toastify";
import Navbar from "../../components/navbar/navbar";
const NFTs = () => {
  const [nfts, setNFTs] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getAllNFTs();
  }, []);
  const getAllNFTs = async () => {
    try {
      const result = await handleRequest("get", "/NFTs?marketplace=true");
      converToTableData(result.data.data);
      setData(result.data.data);
    } catch (err) {
      console.log(err, "error=>");
    }
  };

  const converToTableData = (data) => {
    let tableData = [];
    data.map((item) => {
      if (item?.metadata?.file || item?.metadata?.image) {
        return tableData.push([
          {
            item: (
              <span className="d-flex align-items-center">
                <img
                  src={item?.metadata?.file || item?.metadata?.image}
                  style={{ height: "49px" }}
                  alt=""
                />
              </span>
            ),
          },

          {
            item: (
              <span className="d-flex align-items-center">
                {item?.metadata?.title || item?.metadata?.name || "-no name-"}
              </span>
            ),
          },
          {
            item: (
              <span>
                <Button
                  text={item?.isNFTNonActive ? "Activate" : "Hide"}
                  disabled={loading}
                  handler={() =>
                    activateDeactivate(
                      item?.id?.tokenId,
                      item?.id?.tokenMetadata?.tokenType,
                      item?.contract?.address
                    )
                  }
                />
              </span>
            ),
          },
        ]);
      }
    });
    setNFTs(tableData);
  };

  const activateDeactivate = async (tokenId, tokenType, contractAddress) => {
    try {
      setLoading(true);
      const res = await handleRequest("post", "/nfts/activatedectivate", {
        tokenId,
        tokenType,
        contractAddress,
      });
      getAllNFTs();
      if (res.data.success) {
        setLoading(false);
        toast.success(res.data.message);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  const onSearch = (title) => {
    if (!title.trim()) {
      return converToTableData(data);
    }
    const searchedResult = data.filter((item) => {
      return item?.metadata?.title
        ?.toUpperCase()
        .includes(title?.toUpperCase());
    });
    converToTableData(searchedResult);
  };
  const tableHeadings = ["NFT", "Nft name", "Action"];
  return (
    <>
      <Navbar onSearch={onSearch} />
      <div className="mt-27 px-lg-5 px-md-5 px-3">
        <HeaderTitle title={"NFT's"} />
        <TableView tableHeadings={tableHeadings} tableData={nfts} />
      </div>
    </>
  );
};

export default NFTs;
