import React from 'react'

export default function Error() {
  return (
    <div style={{position: "absolute", left: "50%", top: "40%", textAlign: "center"}}>
        <h1 style={{fontSize: 100}}>404</h1>
        <p style={{fontSize: 70}}>Unauthorized</p>
    </div>
  )
}
