import React, { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import Navbar from "../../components/navbar/navbar";
import HeaderTitle from "../../components/headerTitle/headerTitle";
import styles from "./styles.module.scss";
import { handleRequest } from "../../utils/helpers";
import Button from "../../components/button/button";
import { toast } from "react-toastify";
function Environment() {
  const [environment, setEnvironment] = useState({
    title: "",
    // redirectUrl: "",
    file: null,
    key: "",
  });
  const [error, setError] = useState(null);
  const [disableBtn, setDisableBtn] = useState(false);

  const handlePictureSelected = (event) => {
    var allowedExtensions = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/bmp",
      "image/svg+xml"
    ];
    var uploadPicture = event.target.files[0];
    if (!allowedExtensions.includes(uploadPicture.type)) {
      toast.error("Invalid file type");

      return false;
    }
    setEnvironment({ ...environment, file: event.target.files[0] });
  };

  const validation = () => {
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

    let errors = {};
    if (!environment.title) {
      errors = { ...errors, title: "This field is required" };
    }
    // if (!environment.redirectUrl) {
    //   errors = { ...errors, redirectUrl: 'This field is required' };
    // }
    // if (!!environment.redirectUrl && !urlRegex.test(environment.redirectUrl)) {
    //   errors = { ...errors, redirectUrl: 'Please enter the correct url' };
    // }

    if (!environment.file) {
      errors = { ...errors, file: "Please upload the file" };
    }
    if (!environment.key) {
      errors = { ...errors, key: "This field is required" };
    }

    if (Object.keys(errors).length) {
      setError(errors);
      return false;
    }
    return true;
  };
  const onSubmit = async () => {
    if (!validation()) {
      return false;
    }
    setDisableBtn(true);
    var formData = new FormData();
    formData.append("name", environment.title);
    formData.append("key", environment.key);
    formData.append("environment", environment.file);

    const response = await handleRequest("post", "/galleries/add", formData);
    if (response.data.success) {
      toast.success(response.data.message);
      const file = document.querySelector("#file");
      file.value = "";
      setEnvironment({ title: "", file: null, key: "" });
    }
    disableBtn(false);
  };
  return (
    <>
      <Navbar />
      <div className="d-flex justify-content-center px-lg-5 px-md-5 px-3 flex-column">
        <HeaderTitle title={"Environment"} />
        <Row className={styles.envForm}>
          <Col md={6}>
            <span className="d-flex align-items-center mb-2">
              <Form.Label className="mb-0" htmlFor="title">
                Title*:
              </Form.Label>
              {error?.title && <p className="error mb-0">{error?.title}</p>}
            </span>
            <Form.Control
              id="title"
              type="text"
              placeholder="Title"
              className="mb-15 no-arrows"
              name="title"
              value={environment.title}
              onChange={(e) => {
                setEnvironment({ ...environment, title: e.target.value });
                setError({ ...error, title: "" });
              }}
            />
          </Col>
          <Col md={6}>
            <span className="d-flex align-items-center mb-2">
              <Form.Label className="mb-0" htmlFor="URL">
                Upload File*:
              </Form.Label>
              {error?.file && <p className="error mb-0">{error.file}</p>}
            </span>

            <Form.Control
              id="file"
              type="file"
              className="mb-15 no-arrows"
              name="file"
              onChange={(e) => {
                handlePictureSelected(e);
                setError({ ...error, file: "" });
              }}
              // value={environment.file?.name}
            />
          </Col>
          <Col md={6}>
            <span className="d-flex align-items-center mb-2">
              <Form.Label className="mb-0" htmlFor="title">
                Key*:
              </Form.Label>
              {error?.key && <p className="error mb-0">{error?.key}</p>}
            </span>
            <Form.Control
              id="key"
              type="text"
              placeholder="key"
              className="mb-15 no-arrows"
              name="key"
              value={environment.key}
              onChange={(e) => {
                setEnvironment({ ...environment, key: e.target.value });
                setError({ ...error, key: "" });
              }}
            />
          </Col>
        </Row>
        <span>
          <Button
            text="Submit"
            className={"mt-3"}
            handler={onSubmit}
            disabled={disableBtn}
          />
        </span>
      </div>
    </>
  );
}

export default Environment;
