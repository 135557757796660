import styles from "./styles.module.scss";
import { images } from "../../assets/index";
import { ReactComponent as StatsLogo } from "../../assets/images/stats.svg";
const StatsCard = ({ data }) => {
  const { count, text } = data;
  return (
    <div className={styles.statsCardContainer}>
      <span className="d-flex align-items-center">
        <StatsLogo className={styles.icon} />
        <span className="mx-3">
          <p className={styles.statsCount}>{count}</p>
          <p className={styles.statsText}>{text}</p>
        </span>
      </span>
    </div>
  );
};
export default StatsCard;
