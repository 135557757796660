import styles from "./styles.module.scss";
const Button = ({ text, handler, disabled, className }) => {
  return (
    <button
      className={`${styles.button} ${className}`}
      onClick={() => handler && handler()}
      disabled={disabled}
    >
      {text}
    </button>
  );
};
export default Button;
