
import { useContext } from "react";
import SupportMessages from "../../components/supportMessages";
import HeaderTitle from "../../components/headerTitle/headerTitle";
import Navbar from "../../components/navbar/navbar";
import { useEffect, useState } from "react";
import { handleRequest } from "../../utils/helpers";
import SocketContext from "../../context/context";
const Support = () => {
  const [messages, setMessages] = useState(null);
  const [messagesData, setMessagesData] = useState(null);
  const { socket } = useContext(SocketContext);
  useEffect(() => {
    getSupportMessages();
  }, []);
  const getSupportMessages = async () => {
    try {
      const res = await handleRequest("get", "/support");
      if (res.data.success) {
        setMessages(res.data.data);
        setMessagesData(res.data.data);
      } else {
      }
    } catch (err) {}
  };
  const onSearch = (title) => {
    if (!title.trim()) {
      return setMessages(messagesData);
    }
    const searchedResult = messagesData.filter((item) => {
      return (
        item?.description?.toUpperCase().includes(title?.toUpperCase()) ||
        item?.name?.toUpperCase().includes(title?.toUpperCase())
      );
    });
    setMessages(searchedResult);
  };
  useEffect(() => {
    if (socket) {
      socket?.on("contactUs", ({ }) => {
        getSupportMessages()
      });
      return () => {};
    }
  }, [socket]);
  return (
    <>
      <Navbar onSearch={onSearch} />
      <div className="mt-27 px-lg-5 px-md-5 px-3">
        <HeaderTitle title={"Support Messages"} />
        <SupportMessages messages={messages} />
      </div>
    </>
  );
};

export default Support;
