import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import Navbar from "../../components/navbar/navbar";
import HeaderTitle from "../../components/headerTitle/headerTitle";
import styles from "./styles.module.scss";
import { handleRequest } from "../../utils/helpers";
import Button from "../../components/button/button";
import { toast } from "react-toastify";
import SVG from "../../assets/SVG";
import TableView from "../../components/table/table";

function FeaturedProducts() {
  const [product, setProduct] = useState({
    title: "",
    file: null,
  });
  const [error, setError] = useState(null);
  const [disableBtn, setDisableBtn] = useState(false);
  const [data, setData] = useState(null);
  const [products, setProducts] = useState([]);
  const tableHeadings = ["#", "Title" ,"Souvenir Image", "Action"];

  const handlePictureSelected = (event) => {
    const MAX_FILE_SIZE = 5120 // 5MB
    var allowedExtensions = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/bmp",
      "image/svg+xml"
    ];
    var uploadPicture = event.target.files[0];
    const fileSizeKiloBytes = uploadPicture.size / 1024;

    if (!allowedExtensions.includes(uploadPicture.type)) {
      toast.error("Invalid file type");
      return false;
    }
    if(fileSizeKiloBytes > MAX_FILE_SIZE){
      toast.error("Please upload a file smaller than 5 MB");
      return false
    }
    setProduct({ ...product, file: event.target.files[0] });
  };

  const converToTableData = (data) => {
    let tableData = [];
    data.map((item, index) => {
      tableData.push([
        {
          item: index + 1,
        },
        {
          item: item?.title || "N/A",
        },
        {
          item: (
            <img
              src={`${process.env.REACT_APP_FILE_URL}${item.fileName}`}
              height={120}
              width={120}
              className="mt-1 mb-1"
              alt={item?.title}
            />
          ),
        },
        {
          item: (
            <span>
              <SVG.RemoveIcon
                className="cursor-pointer"
                onClick={() => removeProduct(item?._id)}
              />
            </span>
          ),
        },
       
      ]);
    });
    setProducts(tableData);
  };

  const validation = () => {

    let errors = {};
    if (!product.title) {
      errors = { ...errors, title: "This field is required" };
    }
    if (!product.file) {
      errors = { ...errors, file: "Please upload the file" };
    }

    if (Object.keys(errors).length) {
      setError(errors);
      return false;
    }
    return true;
  };
  const onSubmit = async () => {
    if (!validation()) {
      return false;
    }
    setDisableBtn(true);
    var formData = new FormData();
    formData.append("title", product.title);
    formData.append("file", product.file);

    const response = await handleRequest("post", "/feature-product/add", formData);
    if (response.data.success) {
      getFeaturedProducts()
      toast.success(response.data.message);
      const file = document.querySelector("#file");
      file.value = "";
      setProduct({ title: "", file: null });
    }
    setDisableBtn(false);
  };

  useEffect(() => {
    getFeaturedProducts();
  }, []);

  const getFeaturedProducts = async () => {
    try {
      const result = await handleRequest("get", "/feature-product");
      if (result?.data?.length > 0) {
          setData(result.data);
          converToTableData(result.data);
      }
    } catch (err) {
      console.log(err, "error=>");
    }
  };

  const removeProduct = async (id) => {
    try {
      const res = await handleRequest("delete", `/feature-product/${id}`);
      if (res.data.success) {
        getFeaturedProducts();
        toast.success(res.data.message);
      }
    } catch (err) {
    }
  };

  return (
    <>
      <Navbar />
      <div className="d-flex justify-content-center px-lg-5 px-md-5 px-3 flex-column">
        <HeaderTitle title={"Featured Souvenirs"} />
        <Row className={styles.envForm}>
          <Col md={6}>
            <span className="d-flex align-items-center mb-2">
              <Form.Label className="mb-0" htmlFor="title">
                Title*:
              </Form.Label>
              {error?.title && <p className="error mb-0">{error?.title}</p>}
            </span>
            <Form.Control
              id="title"
              type="text"
              placeholder="Title"
              className="mb-15 no-arrows"
              name="title"
              value={product.title}
              onChange={(e) => {
                setProduct({ ...product, title: e.target.value });
                setError({ ...error, title: "" });
              }}
            />
          </Col>
         
          <Col md={6}>
            <span className="d-flex align-items-center mb-2">
              <Form.Label className="mb-0" htmlFor="URL">
                Souvenir Image*:
              </Form.Label>
              {error?.file && <p className="error mb-0">{error.file}</p>}
            </span>

            <Form.Control
              id="file"
              type="file"
              className="mb-15 no-arrows"
              name="file"
              onChange={(e) => {
                // setProduct({ ...product, file: e.target.value });
                handlePictureSelected(e);
                setError({ ...error, file: "" });
              }}
              // value={product.file?.name}
            />
          </Col>
        </Row>
        <span>
          <Button
            text="Submit"
            className={"mt-3"}
            handler={onSubmit}
            disabled={disableBtn}
          />
        </span>
        <div className="mt-5">
          <TableView tableHeadings={tableHeadings} tableData={products} />
        </div>
      </div>
    </>
  );
}

export default FeaturedProducts;
