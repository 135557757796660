import React, { useEffect, useState, useRef } from "react";
import styles from "./styles.module.scss";
import { handleRequest } from "../../utils/helpers";
import moment from "moment";
import { toast } from "react-toastify";
function EnvironmentChat({ socket, data }) {
  const [chat, setChat] = useState(null);
  const messagesEndRef = useRef(null);
  const getChat = async () => {
    try {
      const res = await handleRequest("get", "/chat", null, {
        id: data[0]?.key,
      });
      if (res.data.success) {
        setChat(res.data.data[0].messages);
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      } else {
      }
    } catch (err) {}
  };
  useEffect(() => {
    if (data?.length > 0) {
      console.log(data, "data=>");
      chat ?? getChat();
    }
  }, [data]);
  useEffect(() => {
    if (socket && data?.length > 0) {
      socket?.emit("join", data[0]?.key);
      socket?.on("message", ({ chatId }) => {
        console.log("a message is sent to", chatId);
        getChat();
      });
      return () => {
        socket.emit("leave", "Metaverse City");
      };
    }
  }, [socket, data]);

  const convertSpritesToEmojis = (messageText) => {
    const convertedText = messageText.replace(
      /<sprite name="([^"]+)">/g,
      (match, spriteName) => {
        const name = spriteName.split('-');
        spriteName = name.length > 1 ? name[0] : spriteName;
        const emojiUnicode = `&#x${spriteName};`;
        return emojiUnicode ? emojiUnicode : match;
      }
    );
    return convertedText;
  };

  return (
    <div className={styles.envChat}>
      <div className="chat-header">Space Name</div>

      <div className="chat-area">
        {chat &&
          chat.map((msg, i) => {
            return (
              <div className="chat send" key={i}>
                <div>
                  <div className="name">{msg?.userName || msg?.sender}</div>
                  <div
                    className="msg"
                    dangerouslySetInnerHTML={{
                      __html: convertSpritesToEmojis(msg.message),
                    }}
                  />
                </div>
                <span className="date">
                  {moment(msg.timestamp).format("ddd h:mm A")}
                </span>
              </div>
            );
          })}
        <div ref={messagesEndRef} />
      </div>
    </div>
  );
}

export default EnvironmentChat;
