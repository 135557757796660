import axios from "axios";
import { ENV } from "../config";
import { toast } from 'react-toastify';
export const handleRequest = async (
  method,
  endpoint,
  data = null,
  params = null
) => {
  try {
    let url = ENV.url + endpoint;
    let headers = {
      Authorization: `Bearer ${ENV.getUserKeys("accessToken").accessToken}`,
    };
    const res = await axios({
      method,
      url,
      ...(data && { data }),
      headers,
      ...(params && { params }),
    });
    return res;
  } catch (err) {
    if (err?.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const errorMessage = err?.response?.data?.message || 'An error occurred';
      toast.error(errorMessage);
    } else if (err?.request) {
      // The request was made but no response was received
      toast.error('No response received from the server');
    } else {
      // Something happened in setting up the request that triggered an Error
      toast.error('Error occurred while making the request');
    }
    throw new Error(err);
  }
};
export const validateCoordinates = (shop_lat, shop_lng, pre_caravan_lat, pre_caravan_lng, current_caravan_lat, current_caravan_lng, setError) => {
  try {
    const latitudePattern = /^-?([0-9]|[1-8][0-9]|90)\.\d{1,6}$/;
    const longitudePattern = /^-?([0-9]|[1-9][0-9]|1[0-7][0-9]|180)\.\d{1,6}$/;
    if (shop_lat.trim() === "" || shop_lng.trim() === "" || pre_caravan_lat.trim() === "" || pre_caravan_lng.trim() === "" || current_caravan_lat.trim() === "" || current_caravan_lng.trim() === "") {
      setError({
        shop_lat: shop_lat.trim() === "",
        shop_lng: shop_lng.trim() === "",
        pre_caravan_lat: pre_caravan_lat.trim() === "",
        pre_caravan_lng: pre_caravan_lng.trim() === "",
        current_caravan_lat: current_caravan_lat.trim() === "",
        current_caravan_lng: current_caravan_lng.trim() === "",
        message: "This field is required",
      });
      return false;
    }

    if (
      !shop_lat?.match(latitudePattern) ||
      !shop_lng?.match(longitudePattern) ||
      !pre_caravan_lat?.match(latitudePattern) ||
      !pre_caravan_lng?.match(longitudePattern) ||
      !current_caravan_lat?.match(latitudePattern) ||
      !current_caravan_lng?.match(longitudePattern)
    ) {

      setError({
        shop_lat: shop_lat?.match(latitudePattern) ? false : true,
        shop_lng: shop_lng?.match(longitudePattern) ? false : true,
        pre_caravan_lat: pre_caravan_lat?.match(latitudePattern) ? false : true,
        pre_caravan_lng: pre_caravan_lng?.match(longitudePattern) ? false : true,
        current_caravan_lat: current_caravan_lat?.match(latitudePattern) ? false : true,
        current_caravan_lng: current_caravan_lng?.match(longitudePattern) ? false : true,
        message: "Invalid field value",
      });
      return false;
    }

    // If both latitude and longitude are valid
    return true;
  } catch (err) {
    console.log(err, "errpr");
  }
};
export const handleScroll = (event) => {
  event?.currentTarget?.blur();
};
