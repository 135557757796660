import { NavLink } from "react-router-dom";
import styles from "./styles.module.scss";
import SVG from "../../assets/SVG";
import { images } from "../../assets";
import { ENV } from "../../config";

const Sidebar = ({ setShowMenu }) => {
  return (
    <div className={styles.sidebarWrap}>
      <div className={styles.sidebarContainer}>
        <span
          className="close-icon d-lg-none d-inline-block"
          onClick={() => setShowMenu(false)}
        >
          <SVG.CloseIcon />
        </span>
        <div className={styles.mainIconContainer}>
          <SVG.Logo />
        </div>

        <div className={styles.sideItemsContainer}>
          {ENV.getUserKeys("role")?.role === "admin" &&
            <>
          <NavLink className="sidebar-item" to="/" onClick={() => setShowMenu(false)}>
            <span className="sidebar-icon">
              <SVG.DBIcon />
            </span>
            <p className="dashboard-item-text">Dashboard</p>
          </NavLink>

          <NavLink className="sidebar-item" to="/support" onClick={() => setShowMenu(false)}>
            <span className="sidebar-icon">
              <SVG.SupportIcon />
            </span>
            <p className="dashboard-item-text">Support messages</p>
          </NavLink>

          <NavLink className="sidebar-item" to="/nfts" onClick={() => setShowMenu(false)}>
            <span className="sidebar-icon">
              <SVG.NFTIcon />
            </span>
            <p className="dashboard-item-text">NFT's</p>
          </NavLink>

          <NavLink className="sidebar-item" to="/map-location" onClick={() => setShowMenu(false)}> 
            <span className="sidebar-icon">
              <SVG.MapLocation />
            </span>
            <p className="dashboard-item-text">Map Location</p>
          </NavLink>
          <NavLink className="sidebar-item" to="/environment" onClick={() => setShowMenu(false)}>
            <span className="sidebar-icon">
              <SVG.NFTIcon />
            </span>
            <p className="dashboard-item-text">Environment</p>
          </NavLink>
          <NavLink className="sidebar-item" to="/featured-products" onClick={() => setShowMenu(false)}>
            <span className="sidebar-icon">
              <SVG.Cart />
            </span>
            <p className="dashboard-item-text">Featured Souvenirs</p>
          </NavLink>
          <NavLink className="sidebar-item" to="/souvenirs-categories" onClick={() => setShowMenu(false)}>
            <span className="sidebar-icon">
              <SVG.Cart />
            </span>
            <p className="dashboard-item-text">Souvenir Categories</p>
          </NavLink>
          <NavLink className="sidebar-item" to="/gift-souvenirs" onClick={() => setShowMenu(false)}>
            <span className="sidebar-icon">
            <img src={images.gift} width={24} height={24} />
            </span>
            <p className="dashboard-item-text">Gift Souvenirs</p>
          </NavLink>
          </>}
          {["admin", "shipping_company"].includes(ENV.getUserKeys("role")?.role) &&
          <NavLink className="sidebar-item" to="/orders" onClick={() => setShowMenu(false)}>
            <span className="sidebar-icon">
              <SVG.NFTIcon />
            </span>
            <p className="dashboard-item-text">Orders History</p>
          </NavLink>}
          {ENV.getUserKeys("role")?.role === "admin" && <>
          <NavLink className="sidebar-item" to="/content" onClick={() => setShowMenu(false)}>
            <span className="sidebar-icon">
              <SVG.NFTIcon />
            </span>
            <p className="dashboard-item-text">Home Page Content</p>
          </NavLink>
          <NavLink className="sidebar-item" to="/artist" onClick={() => setShowMenu(false)}>
            <span className="sidebar-icon">
              <SVG.NFTIcon />
            </span>
            <p className="dashboard-item-text">Artist</p>
          </NavLink>
           <NavLink className="sidebar-item" to="/wallet" onClick={() => setShowMenu(false)}>
            <span className="sidebar-icon">
              <SVG.NFTIcon />
            </span>
            <p className="dashboard-item-text">Wallet</p>
          </NavLink>
          
          <NavLink className="sidebar-item" to="/user-management" onClick={() => setShowMenu(false)}>
            <span className="sidebar-icon">
              <SVG.Users />
            </span>
            <p className="dashboard-item-text">User Management</p>
          </NavLink>
          </>}
        </div>
      </div>
    </div>
  );
};
export default Sidebar;
