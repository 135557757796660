// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_layoutContainer__JtiJx {\n  display: flex;\n  height: 100vh;\n  position: relative;\n}\n.styles_layoutContainer__JtiJx .styles_sidebar__JFbEB {\n  width: 314px;\n}\n@media (max-width: 991px) {\n  .styles_layoutContainer__JtiJx .styles_sidebar__JFbEB {\n    display: none;\n    position: absolute;\n    height: 100vh;\n    z-index: 9999;\n  }\n  .styles_layoutContainer__JtiJx .styles_sidebar__JFbEB.styles_show__xWO5K {\n    display: block;\n  }\n}\n.styles_layoutContainer__JtiJx .styles_content__JJKNe {\n  flex: 1 1;\n  background: #f5f5f5;\n  height: 100vh;\n  overflow-y: auto;\n}", "",{"version":3,"sources":["webpack://./src/pages/layouts/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,kBAAA;AACF;AAAE;EACE,YAAA;AAEJ;AADI;EAFF;IAGI,aAAA;IACA,kBAAA;IACA,aAAA;IACA,aAAA;EAIJ;EAHI;IACE,cAAA;EAKN;AACF;AAFE;EACE,SAAA;EACA,mBAAA;EACA,aAAA;EACA,gBAAA;AAIJ","sourcesContent":[".layoutContainer {\n  display: flex;\n  height: 100vh;\n  position: relative;\n  .sidebar {\n    width: 314px;\n    @media (max-width: 991px) {\n      display: none;\n      position: absolute;\n      height: 100vh;\n      z-index: 9999;\n      &.show {\n        display: block;\n      }\n    }\n  }\n  .content {\n    flex: 1;\n    background: #f5f5f5;\n    height: 100vh;\n    overflow-y: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layoutContainer": "styles_layoutContainer__JtiJx",
	"sidebar": "styles_sidebar__JFbEB",
	"show": "styles_show__xWO5K",
	"content": "styles_content__JJKNe"
};
export default ___CSS_LOADER_EXPORT___;
