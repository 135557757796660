// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_statsCardContainer__P3z-i {\n  background: #ffffff;\n  box-shadow: 0px 1px 3px rgba(0, 81, 175, 0.1);\n  border-radius: 10px;\n  min-height: 112px;\n  display: flex;\n  align-items: center;\n  padding: 20px;\n}\n.styles_statsCardContainer__P3z-i .styles_icon__FlHTw {\n  height: 30px;\n  width: 30px;\n}\n.styles_statsCardContainer__P3z-i .styles_statsCount__nVslE {\n  font-style: normal;\n  font-weight: bold;\n  font-size: 32px;\n  line-height: 40px;\n  color: rgba(0, 0, 0, 0.75);\n  margin-bottom: 0;\n}\n.styles_statsCardContainer__P3z-i .styles_statsText__wUV2D {\n  color: rgba(0, 0, 0, 0.5);\n  font-family: \"Mulish\";\n  font-style: normal;\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 24px;\n  margin-bottom: 0;\n}", "",{"version":3,"sources":["webpack://./src/components/statsCard/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,6CAAA;EACA,mBAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,aAAA;AACF;AAAE;EACE,YAAA;EACA,WAAA;AAEJ;AAAE;EACE,kBAAA;EACA,iBAAA;EACA,eAAA;EACA,iBAAA;EACA,0BAAA;EACA,gBAAA;AAEJ;AAAE;EACE,yBAAA;EACA,qBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;AAEJ","sourcesContent":[".statsCardContainer {\n  background: #ffffff;\n  box-shadow: 0px 1px 3px rgba(0, 81, 175, 0.1);\n  border-radius: 10px;\n  min-height: 112px;\n  display: flex;\n  align-items: center;\n  padding: 20px;\n  .icon {\n    height: 30px;\n    width: 30px;\n  }\n  .statsCount {\n    font-style: normal;\n    font-weight: bold;\n    font-size: 32px;\n    line-height: 40px;\n    color: rgba(0, 0, 0, 0.75);\n    margin-bottom: 0;\n  }\n  .statsText {\n    color: rgba(0, 0, 0, 0.5);\n    font-family: \"Mulish\";\n    font-style: normal;\n    font-weight: 500;\n    font-size: 14px;\n    line-height: 24px;\n    margin-bottom: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statsCardContainer": "styles_statsCardContainer__P3z-i",
	"icon": "styles_icon__FlHTw",
	"statsCount": "styles_statsCount__nVslE",
	"statsText": "styles_statsText__wUV2D"
};
export default ___CSS_LOADER_EXPORT___;
