// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_button__ARIEW {\n  display: inline-block;\n  padding: 5px 20px;\n  height: 34px;\n  background: #5fbb46;\n  border: none;\n  font-weight: 700;\n  color: #fff;\n  border-radius: 4px;\n}", "",{"version":3,"sources":["webpack://./src/components/button/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,iBAAA;EACA,YAAA;EACA,mBAAA;EACA,YAAA;EACA,gBAAA;EACA,WAAA;EACA,kBAAA;AACF","sourcesContent":[".button {\n  display: inline-block;\n  padding: 5px 20px;\n  height: 34px;\n  background: #5fbb46;\n  border: none;\n  font-weight: 700;\n  color: #fff;\n  border-radius: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "styles_button__ARIEW"
};
export default ___CSS_LOADER_EXPORT___;
