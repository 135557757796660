import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import Navbar from "../../components/navbar/navbar";
import HeaderTitle from "../../components/headerTitle/headerTitle";
import styles from "./styles.module.scss";
import { handleRequest } from "../../utils/helpers";
import Button from "../../components/button/button";
import { toast } from "react-toastify";
function Environment() {
  const initialState = {
    en_hero_title: "",
    en_hero_desc: "",
    en_hero_btn: "",
    en_gallery_title: "",
    en_connect_desc: "",
    en_souvenir_title: "",
    en_souvenir_desc: "",
    en_footer_desc: "",
    sa_hero_title: "",
    sa_hero_desc: "",
    sa_hero_btn: "",
    sa_gallery_title: "",
    sa_connect_desc: "",
    sa_souvenir_title: "",
    sa_souvenir_desc: "",
    sa_footer_desc: "",
  };
  const [content, setContent] = useState(initialState);
  const [disableBtn, setDisableBtn] = useState(false);

  const getContent = () => {
    handleRequest("get", "/content").then((response) => {
      if (response?.data?.en_hero_title) {
        setContent(response.data);
      }
    });
  };
  useEffect(() => {
    getContent();
  }, []);
  const onSubmit = async () => {
    setDisableBtn(true);

    const response = await handleRequest("post", "/content/update", content);
    if (response.data.success) {
      toast.success(response.data.message);
      getContent();
    }
    setDisableBtn(false);
  };

  const InputField = ({
    type = "text",
    label,
    en_name,
    placeholder,
    arabic_title,
    arabic_name,
  }) => {
    return (
      <>
        <Col md={6}>
          <span className="d-flex align-items-center mb-2">
            <Form.Label className="mb-0" htmlFor={label}>
              {label}:
            </Form.Label>
            {/* {error && <p className="error mb-0">{error[name]}</p>} */}
          </span>
          {type === "textarea" ? (
            <Form.Control
              id={en_name}
              as="textarea"
              rows={2}
              placeholder={placeholder}
              className="mb-15 no-arrows"
              name={en_name}
              value={content[en_name]}
              onChange={(e) => {
                setContent({ ...content, [e.target.name]: e.target.value });
                // setError({ ...error, [name]: "" });
              }}
            />
          ) : (
            <Form.Control
              id={en_name}
              type={type}
              placeholder={placeholder}
              className="mb-15 no-arrows"
              name={en_name}
              value={content[en_name]}
              onChange={(e) => {
                setContent({ ...content, [e.target.name]: e.target.value });
                // setError({ ...error, [name]: "" });
              }}
            />
          )}
        </Col>
        <Col md={6} style={{ direction: "rtl" }}>
          <span className="d-flex align-items-center mb-2">
            <Form.Label className="mb-0" htmlFor={label}>
              {arabic_title}:
            </Form.Label>
            {/* {error && <p className="error mb-0">{error[name]}</p>} */}
          </span>
          {type === "textarea" ? (
            <Form.Control
              id={arabic_name}
              as="textarea"
              rows={2}
              placeholder={arabic_title}
              className="mb-15 no-arrows"
              name={arabic_name}
              value={content[arabic_name]}
              onChange={(e) => {
                setContent({ ...content, [e.target.name]: e.target.value });
                // setError({ ...error, [name]: "" });
              }}
            />
          ) : (
            <Form.Control
              id={arabic_name}
              type={type}
              placeholder={arabic_title}
              className="mb-15 no-arrows"
              name={arabic_name}
              value={content[arabic_name]}
              onChange={(e) => {
                setContent({ ...content, [e.target.name]: e.target.value });
                // setError({ ...error, [name]: "" });
              }}
            />
          )}
        </Col>
      </>
    );
  };

  const Title = ({ label }) => {
    return (
      <Col md={12}>
        {" "}
        <p className={styles.title}>{label}</p>
      </Col>
    );
  };

  return (
    <>
      <Navbar />
      <div className="d-flex justify-content-center px-lg-5 px-md-5 px-3 flex-column">
        <HeaderTitle title={"Home Page Content"} />
        <Row className={styles.envForm}>
          {Title({ label: "Hero Section" })}

          {InputField({
            label: "Title",
            en_name: "en_hero_title",
            placeholder: "Title",
            arabic_title: "عنوان",
            arabic_name: "sa_hero_title",
          })}
          {InputField({
            type: "textarea",
            label: "Description",
            en_name: "en_hero_desc",
            placeholder: "Description",
            arabic_title: "تفصیل",
            arabic_name: "sa_hero_desc",
          })}
          {InputField({
            label: "Button Text",
            en_name: "en_hero_btn",
            placeholder: "Button",
            arabic_title: "بٹن کا متن",
            arabic_name: "sa_hero_btn",
          })}

          {Title({ label: "Gallery" })}
          {InputField({
            label: "Title",
            en_name: "en_gallery_title",
            placeholder: "Title",
            arabic_title: "عنوان",
            arabic_name: "sa_gallery_title",
          })}

          {Title({ label: "Connect With Us Section" })}
          {InputField({
            label: "Title",
            en_name: "en_connect_desc",
            placeholder: "Description",
            arabic_title: "عنوان",
            arabic_name: "sa_connect_desc",
          })}

          {Title({ label: "Souvenirs" })}
          {InputField({
            label: "Title",
            en_name: "en_souvenir_title",
            placeholder: "Title",
            arabic_title: "عنوان",
            arabic_name: "sa_souvenir_title",
          })}
          {InputField({
            type: "textarea",
            label: "Description",
            en_name: "en_souvenir_desc",
            placeholder: "Description",
            arabic_title: "تفصیل",
            arabic_name: "sa_souvenir_desc",
          })}

          {Title({ label: "Footer" })}
          {InputField({
            type: "textarea",
            label: "Description",
            en_name: "en_footer_desc",
            placeholder: "Description",
            arabic_title: "تفصیل",
            arabic_name: "sa_footer_desc",
          })}
        </Row>
        <span>
          <Button
            text="Update"
            className={"mt-3 mb-4"}
            handler={onSubmit}
            disabled={disableBtn}
          />
        </span>
      </div>
    </>
  );
}

export default Environment;
