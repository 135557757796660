import React from 'react';
import { Col, Form } from "react-bootstrap";

const InputField = ({label, name, placeholder, value, onChange, error, type}) => {
    return (
        <Col md={3}>
            <span className="d-flex align-items-center mb-2">
              <Form.Label className="mb-0">
                {label}
              </Form.Label>
              {error[name] && <p className="error mb-0">{error?.message}</p>}
            </span>
            <Form.Control
              id={name}
              type={type}
              placeholder={placeholder}
              className="mb-15 no-arrows"
              name={name}
              onChange={onChange}
            //   onWheel={handleScroll}
              // onKeyDown={handleScroll}
              value={value}
            />
          </Col>
    );
}

export default InputField;
