import React, { useEffect, useRef } from "react";
import { Row, Col, Form } from "react-bootstrap";
import styles from "./styles.module.scss";
import Button from "../button/button";

export default function ProductForm(props) {
  const {
    user,
    error,
    setUser,
    setError,
    onSubmit,
    disableBtn,
  } = props;

  return (
    <>
      <Row className={styles.productForm}>
          <Col md={6}>
            <span className="d-flex align-items-center mb-2">
              <Form.Label className="mb-0" htmlFor="title">
                Name*:
              </Form.Label>
              {error?.userName && <p className="error mb-0">{error?.userName}</p>}
            </span>
            <Form.Control
              id="userName"
              type="text"
              placeholder="Name"
              className="mb-15 no-arrows"
              name="userName"
              value={user.userName}
              onChange={(e) => {
                setUser({ ...user, userName: e.target.value });
                setError({ ...error, userName: "" });
              }}
            />
          </Col>
          <Col md={6}>
            <span className="d-flex align-items-center mb-2">
              <Form.Label className="mb-0" htmlFor="title">
                Email*:
              </Form.Label>
              {error?.email && <p className="error mb-0">{error?.email}</p>}
            </span>
            <Form.Control
              id="email"
              type="email"
              placeholder="Name"
              className="mb-15 no-arrows"
              name="email"
              value={user.email}
              onChange={(e) => {
                setUser({ ...user, email: e.target.value });
                setError({ ...error, email: "" });
              }}
            />
          </Col>
            <Col md={6}>
              <span className="d-flex align-items-center mb-2">
                <Form.Label className="mb-0" htmlFor="title">
                  Select Role*:
                </Form.Label>
                {error?.role && (
                  <p className="error mb-0">{error?.role}</p>
                )}
              </span>

              <Form.Select
                className="mb-15 form-select"
                name="role"
                value={user.role}
                onChange={(e) => {
                  setUser({ ...user, role: e.target.value });
                  setError({ ...error, role: "" });
                }}
              >
                <option value="shipping_company">Shipping Company</option>
              </Form.Select>
            </Col>
            <Col md={6}>
            <span className="d-flex align-items-center mb-2">
              <Form.Label className="mb-0" htmlFor="title">
                Password*:
              </Form.Label>
              {error?.password && <p className="error mb-0">{error?.password}</p>}
            </span>
            <Form.Control
              id="password"
              type="text"
              placeholder="Password"
              className="mb-15 no-arrows"
              name="password"
              value={user.password}
              onChange={(e) => {
                setUser({ ...user, password: e.target.value });
                setError({ ...error, password: "" });
              }}
            />
          </Col>
      </Row>
      <span>
        <Button
          text="Submit"
          className={"mt-3"}
          handler={onSubmit}
          disabled={disableBtn}
        />
      </span>
    </>
  );
}
