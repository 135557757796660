import React, { useState } from "react";
import styles from "./styles.module.scss";
import signin from "../../assets/images/sign-in-img.png";
import { ReactComponent as Logo } from "../../assets/images/drb-logo.svg";
import { ReactComponent as Shape } from "../../assets/images/shape.svg";
import { ReactComponent as DesignShape } from "../../assets/images/bottom-design.svg";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { handleRequest } from "../../utils/helpers";
import metamask from "../../assets/images/metamask.svg";
import {
  handleSign,
  validateMetamask,
  isMetaMaskInstalled,
  redirectToMetaMaskExtension
} from "../../utils/web3";
import { ENV } from "../../config";
const Signin = () => {
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const login = async () => {
    try {
      setLoading(true);
      const res = await handleRequest("post", "/user/auth/login", credentials);
      console.log(res, "res=>");
      if (res.data.success) {
        ENV.encryptUserData({
          accessToken: res.data.data.token,
          ...res.data.data.user,
        });
        toast.success("Logged in successfully");
        navigate(res.data.data?.user?.role === "admin" ? "/" : "/orders");
      } else {
        setLoading(false);
        toast.error(res.data.message);
      }
    } catch (err) {
      console.log(err, "error==>");
      setLoading(false);
    }
  };
  const validateUser = async () => {
    try {
      setLoading(true);
      if (!isMetaMaskInstalled()) {
        toast.info("Please install metamask to continue");
        return redirectToMetaMaskExtension();
      }
      const connectedAddress = await validateMetamask();
      const res = await handleRequest(
        "post",
        `/user/auth/metamask/signUser/${connectedAddress}`,
        null
      );
      const { users } = res.data;
      if (users.length > 0) {
        const { address, signature } = await handleSign(users[0].nounce);
        loginUser(address, signature);
      } else {
        signUpUser(connectedAddress);
      }
    } catch (err) {
      setLoading(false);
      console.log(err, "error=>");
    }
  };
  const signUpUser = async (connectedAddress) => {
    try {
      const res = await handleRequest(
        "post",
        `/user/auth/metamask/register/${connectedAddress}`
      );
      if (res.data.success) {
        const { address, signature } = await handleSign(res.data.data.nounce);
        loginUser(address, signature);
      } else {
        setLoading(false);
        toast.error(res?.data?.message);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const loginUser = async (address, signature) => {
    try {
      const res = await handleRequest(
        "post",
        `/user/auth/metamask/login/${address}`,
        {
          address,
          signature,
          role: "admin",
        }
      );
      console.log(res, "res==>");
      if (res.data.success) {
        ENV.encryptUserData({
          accessToken: res.data.data.accessToken,
          ...res.data.data._doc,
        });
        toast.success("User logged in successfully");
        navigate("/");
      } else {
        setLoading(false);
        toast.error(res?.data?.message);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  return (
    <>
      <div className="container-fluid px-lg-60 px-0">
        <div className={styles.wrapper}>
          <div className="row content-section py-50 mx-0">
            <div className="col-lg-6 col-xl-7 d-flex justify-content-center z-index-10">
              <div className={styles.img}>
                <img src={signin} className="img-fluid" alt="signin" />
              </div>
            </div>
            <div className="col-lg-6 col-xl-5 d-flex justify-content-center">
              <div className={styles.signInWapper}>
                <Shape className={styles.signInShape} />
                <div className={styles.signIn}>
                  <div className={styles.content}>
                    <Logo className="mb-40" />
                    <Form.Control
                      type="text"
                      placeholder="Email"
                      className="mb-15"
                      name="email"
                      onChange={(e) =>
                        setCredentials({
                          ...credentials,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      name="password"
                      className="mb-30"
                      onChange={(e) =>
                        setCredentials({
                          ...credentials,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                    <button
                      className={styles.login}
                      disabled={loading}
                      onClick={() => {
                        login();
                        // toast.success("User logged in successfully");
                        // navigate("/");
                      }}
                    >
                      Log in
                    </button>
                    <div className={styles.text}>OR</div>
                    <button
                      className={styles.meta}
                      onClick={() => validateUser()}
                      // disabled={loading}
                    >
                      Login with Metamask
                      <img src={metamask} alt="meta" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="py-80 px-50 bg-secondary mx-n60 d-lg-block d-none">
            <div className="container">
              <div className="row">
                <div className="col-md-7 d-flex justify-content-center">
                  <DesignShape />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signin;
