import React, { useState, useEffect } from "react";
import Navbar from "../../components/navbar/navbar";
import { handleRequest } from "../../utils/helpers";
import { toast } from "react-toastify";
import SVG from "../../assets/SVG";
import UserForm from "../../components/userForm";
import HeaderTitle from "../../components/headerTitle/headerTitle";
import TableView from "../../components/table/table";
const CryptoJS = require("crypto-js");

function GiftSouvenirs() {
  const initialUserValue = {
    userName: "",
    email: "",
    role: "shipping_company",
    password: "",
  };
  const [user, setUser] = useState(initialUserValue);
  const [error, setError] = useState(null);
  const [disableBtn, setDisableBtn] = useState(false);
  const [data, setData] = useState(null);
  const [userData, setUserData] = useState([]);

  const tableHeadings = ["#", "Name", "Email", "Action"];
  const converToTableData = (data) => {
    let tableData = [];
    data.length > 0 &&
      data.map((item, index) => {
        tableData.push([
          {
            item: index + 1,
          },
          {
            item: item?.userName || "N/A",
          },
          {
            item: item?.email || "N/A",
          },
          // {
          //   item: item?.password || "N/A",
          // },
          {
            item: (
              <span>
                <SVG.RemoveIcon
                  className="cursor-pointer"
                  onClick={() => removeUser(item?._id)}
                />
              </span>
            ),
          },
        ]);
      });
    setUserData(tableData);
  };

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const validation = () => {
    let errors = {};
    if (!user.userName) {
      errors = { ...errors, userName: "This field is required" };
    }
    if (!user.email) {
      errors = { ...errors, email: "This field is required" };
    }
    if(user.email && !validateEmail(user.email)){
      errors = { ...errors, email: "Please enter the valid email address" };
    }
    if (!user.password) {
      errors = { ...errors, password: "This field is required" };
    }
    if (user.password && user.password.length < 8) {
      errors = { ...errors, password: "Password must be at least 8 characters" };
    }

    if (Object.keys(errors).length) {
      setError(errors);
      return false;
    }
    return true;
  };

  const onSubmit = async () => {
    if (!validation()) {
      return false;
    }
    // setDisableBtn(true);

    const response = await handleRequest(
      "post",
      "/user/auth/register",
      user
    );
    if (response.data.success) {
      toast.success(response.data.message);
      getShippinRoleUserList();
      setDisableBtn(false);
      // const file = document.querySelector("#file");
      // file.value = "";
      setUser(initialUserValue);
    }
    setDisableBtn(false);
  };


  useEffect(() => {
    getShippinRoleUserList();
  }, []);


  const getShippinRoleUserList = async (userId) => {
    try {
      const result = await handleRequest("get", "/user/role/shipping_company");
      if (result?.data?.users?.length > 0) {
        converToTableData(result.data.users);
      } else {
        converToTableData([]);
      }
    } catch (err) {
      console.log(err, "error=>");
    }
  };

  const removeUser = async (userId) => {
    try {
      const result = await handleRequest("delete", "/user/delete", {userId});
      if (result.data.success) {
        getShippinRoleUserList();
        toast.success(result.data.message);
      }
    } catch (err) {}
  };

  return (
    <>
      <Navbar />
      <div className="d-flex justify-content-center px-lg-5 px-md-5 px-3 flex-column">
        <HeaderTitle title="User Management" />
        <UserForm
          user={user}
          error={error}
          setUser={setUser}
          setError={setError}
          onSubmit={onSubmit}
          disableBtn={disableBtn}
        />
         <div className="mt-5">
              <TableView tableHeadings={tableHeadings} tableData={userData} />
            </div>
      </div>
    </>
  );
}

export default GiftSouvenirs;
