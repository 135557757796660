import { useEffect, useState, useContext } from "react";
import { handleRequest } from "../../utils/helpers";
import StatsCard from "../statsCard/statsCard";
import SocketContext from "../../context/context";
import { toast } from "react-toastify";

const Stats = () => {
  const [stats, setStats] = useState(null);
  const { socket } = useContext(SocketContext);
  useEffect(() => {
    getStats();
  }, []);
  const getStats = async () => {
    try {
      const res = await handleRequest("get", "/user/stats");
      if (res.data.success) {
        const { totalUsers, totalLiveUsers, totalEnvironments } = res.data.data;
        setStats([
          {
            count: totalUsers,
            text: "Number of users enrolled"
          },
          {
            count: totalLiveUsers || 0,
            text: "Active users"
          },
          {
            count: totalEnvironments,
            text: "Total Environments"
          }
        ]);
      }
    } catch (err) {
      console.log(err, "error==>");
    }
  };
  useEffect(() => {
    if (socket) {
      socket?.on("userStats", ({}) => {
        getStats();
      });
      return () => {};
    }
  }, [socket]);
  return (
    <div className="row">
      {stats &&
        stats.map((item, index) => {
          return (
            <div className="col-lg-4 col-md-6 mt-3 mt-lg-0" key={index}>
              <StatsCard data={item} />
            </div>
          );
        })}
    </div>
  );
};
export default Stats;
