import { useState, useEffect, useContext } from "react";
import { images } from "../../assets";
import TableView from "../../components/table/table";
import HeaderTitle from "../../components/headerTitle/headerTitle";
import EnvironmentChat from "../../components/environmentChat";
import { handleRequest } from "../../utils/helpers";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Navbar from "../../components/navbar/navbar";
import SocketContext from "../../context/context";
import SVG from "../../assets/SVG";
import {
  getCollection,
  insertRecord,
} from "../../utils/firebase.js/readCollection";
const EnvironmentDetail = () => {
  const [page, setPage] = useState(1);
  const [data, setData] = useState(null);
  const [galleryDetail, setGalleryDetail] = useState(null);
  const { id } = useParams();
  const { socket } = useContext(SocketContext);
  const tableHeadings = ["User name", "Action", "Remove", "Block/Unblock"];
  useEffect(() => {
    getGallery();
  }, []);
  const getGallery = async () => {
    try {
      const result = await handleRequest("get", `/galleries/${id}`, null, {
        page,
      });
      console.log(result, "result");
      if (result.data.success) {
        if (result.data.data.length > 0) {
          setData(result.data.data);
          converToTableData(result.data.data);
        }
      }
    } catch (err) {
      console.log(err, "error=>");
    }
  };

  const converToTableData = (data) => {
    let tableData = [];
    data.map((item, index) => {
      tableData.push([
        // {
        //   item: (
        //     <span className="d-flex align-items-center">
        //       <img
        //         src={galleriesImages[item?.fileName]}
        //         style={{ height: "49px" }}
        //       />
        //       <p className="mx-4">{item.name}</p>
        //     </span>
        //   )
        // },
        {
          item: item?.userInfo?.userName || "-no username-",
        },
        {
          item: (
            <span className="d-flex align-items-center">
              <img
                className="cursor-pointer"
                src={images.mute}
                onClick={() =>
                  muteUnmuteUser(
                    item._id,
                    item.userInfo?._id,
                    item?.liveUserId?.users[index]?.isMuted,
                    item?.name.toLowerCase(),
                    item
                  )
                }
                alt=""
              />
              <p className="mb-0 mx-2">
                {item?.liveUserId?.users[index]?.isMuted ? "unmute" : "mute"}
              </p>
            </span>
          ),
        },
        {
          item: (
            <span>
              <SVG.RemoveIcon
                className="cursor-pointer"
                onClick={() =>
                  removeLiveUser(
                    item?._id,
                    item?.userInfo?._id,
                    item?.name?.toLowerCase()
                  )
                }
              />
            </span>
          ),
        },
        {
          item: (
            <span>
              {item?.userInfo?.isBlocked ? (
                <>
                  <SVG.UnBlock
                    className="cursor-pointer mx-2"
                    style={{ width: "2rem", height: "2rem" }}
                    onClick={() =>
                      blockUser(
                        item?.userInfo?._id,
                        !item?.userInfo?.isBlocked,
                        item?._id,
                        item?.name?.toLowerCase()
                      )
                    }
                  />
                  {"UnBock"}
                </>
              ) : (
                <>
                  <SVG.Block
                    className="cursor-pointer mx-2"
                    style={{ width: "2rem", height: "2rem" }}
                    onClick={() =>
                      blockUser(
                        item?.userInfo?._id,
                        !item?.userInfo?.isBlocked,
                        item._id,
                        item?.name?.toLowerCase()
                      )
                    }
                  />
                  {"Block"}
                </>
              )}
            </span>
          ),
        },
      ]);
    });
    setGalleryDetail(tableData);
  };

  const removeLiveUser = async (galleryId, address, name) => {
    try {
      insertRecord({
        address,
        type: "remove",
        status: "pending",
        // galleryId: name,
        galleryId,
      });
      const res = await handleRequest(
        "post",
        "/galleries/live/removeLiveUser",
        {
          galleryId,
          address,
        }
      );
      if (res.data.success) {
        toast.success("live user is removed successfully");
        getGallery();
      } else {
        toast.error(res.data.message);
      }
    } catch (err) {}
  };

  const muteUnmuteUser = async (
    galleryId,
    address,
    isMuted = false,
    name,
    item
  ) => {
    try {
      console.log(item, "item=>");
      insertRecord({
        address,
        type: `${isMuted ? "unmute" : "mute"}`,
        status: "pending",
        // galleryId: name,
        galleryId,
      });
      const res = await handleRequest("patch", "/galleries/live/muteUnmute", {
        galleryId,
        address,
        isMuted: !isMuted,
      });
      console.log(res, "res");
      getGallery();
    } catch (err) {}
  };
  const blockUser = async (address, isBlocked, galleryId, name) => {
    try {
      if (isBlocked) {
        insertRecord({
          address,
          type: "remove",
          status: "pending",
          // galleryId: name,
          galleryId

        });
      }
      const res = await handleRequest("patch", "/user/block", {
        address,
        isBlocked,
      });
      if (res.data.success) {
        getGallery();
        toast.success(res.data.message);
      }
    } catch (err) {}
  };
  const onSearch = (title) => {
    if (!title.trim()) {
      return converToTableData(data);
    }
    const searchedResult = data.filter((item) => {
      return item?.userInfo?.userName
        ?.toUpperCase()
        .includes(title?.toUpperCase());
    });
    converToTableData(searchedResult);
  };

  useEffect(() => {
    getCollection();
    if (socket) {
      socket?.on("liveUsers", ({}) => {
        getGallery();
      });
      return () => {};
    }
  }, [socket]);
  return (
    <>
      <Navbar onSearch={onSearch} />
      <div className="px-5">
        <HeaderTitle title={"Environment Detail"} />
        <EnvironmentChat socket={socket} data={data} />
        <div className="mt-5">
          <TableView tableHeadings={tableHeadings} tableData={galleryDetail} />
        </div>
      </div>
    </>
  );
};
export default EnvironmentDetail;
