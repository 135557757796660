import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import Navbar from "../../components/navbar/navbar";
import HeaderTitle from "../../components/headerTitle/headerTitle";
import styles from "./styles.module.scss";
import { handleRequest } from "../../utils/helpers";
import Button from "../../components/button/button";
import { toast } from "react-toastify";

function Wallet() {
  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState("");
  const [addressId, setAddressId] = useState("");

  const getWalletAddress = async () => {
    try {
      const res = await handleRequest("get", "/wallet");
      if (res.data.success && res.data.data) {
        setAddress(res.data.data.address || "");
        setAddressId(res.data.data?._id || "")
      } else {
      }
    } catch (err) {}
  };
  const updateWalletAddress = async () => {
    if (!address) {
      setAddressError("This field is required");
      return;
    } else {
      setAddressError("");
    }
    try {
      const res = await handleRequest(
        "put",
        `/wallet/${addressId}`,
        { address }
      );
      if (res.data.success) {
        toast.success(res.data.message);
      } else {
      }
    } catch (err) {}
  };
  useEffect(() => {
    getWalletAddress();
  }, []);
  return (
    <>
      <Navbar />
      <div className="d-flex justify-content-center px-lg-5 px-md-5 px-3 flex-column">
        <HeaderTitle title={"Wallet"} />
        <Row className={styles.walletForm}>
          <Col md={12}>
            <span className="d-flex align-items-center mb-2">
              <Form.Label className="mb-0" htmlFor="address">
                Wallet Address*:
              </Form.Label>
              {addressError && <p className="error mb-0">{addressError}</p>}
            </span>
            <Form.Control
              id="wallet_address"
              type="text"
              placeholder="Wallet Address"
              className="mb-15 no-arrows"
              name="address"
              onChange={(e) => {
                setAddress(e.target.value);
              }}
              value={address}
            />
          </Col>
        </Row>
        <span>
          <Button
            text="save"
            className={"mt-3"}
            handler={() => updateWalletAddress()}
          />
        </span>
      </div>
    </>
  );
}

export default Wallet;
