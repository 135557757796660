import React, { useContext } from "react";
import styles from "./styles.module.scss";
import Sidebar from "../../components/sidebar/sidebar";
import context from "../../context/context";
const LoginLayout = (props) => {
  const { showMenu, setShowMenu } = useContext(context);
  return (
    <div className={styles.layoutContainer}>
      <div className={`${styles.sidebar} ${showMenu ? styles.show : ""}`}>
        <Sidebar showMenu={showMenu} setShowMenu={setShowMenu} />
      </div>
      <div className={styles.content}>{props.children}</div>
    </div>
  );
};

export default LoginLayout;
