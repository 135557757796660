import sidebar from "./images/sidebar.png";
import sidebar1 from "./images/sidebar1.png";
import sidebar2 from "./images/sidebar2.png";
import environment from "./images/environment.png";
import remove from "./images/remove.png";
import mute from "./images/mute.png";
import supportimg from "./images/supportimg.png";
import gift from "./images/gift.png";
import order from "./images/order.png";

export const images = {
  sidebar,
  sidebar1,
  sidebar2,
  environment,
  remove,
  mute,
  supportimg,
  gift,
  order
};
