const CryptoJS = require("crypto-js");

export const ENV = {
  url: process.env.REACT_APP_URL,
  chainId: process.env.REACT_APP_CHAIN_ID,
  WEBSOCKET_URL: process.env.REACT_APP_URL,
  encryptUserData: function (data) {
    const userData = localStorage.getItem("encusedrbadmin");
    if (userData && !data.accessToken) {
      const bytes = CryptoJS.AES.decrypt(
        userData,
        process.env.REACT_APP_DATA_ENCRYPTION_KEY
      );
      let originalData = bytes.toString(CryptoJS.enc.Utf8);
      originalData = JSON.parse(originalData);
      if (originalData && originalData.accessToken) {
        data.accessToken = originalData.accessToken;
      }
    }
    data = JSON.stringify(data);
    const encryptedUser = CryptoJS.AES.encrypt(
      data,
      process.env.REACT_APP_DATA_ENCRYPTION_KEY
    ).toString();
    localStorage.setItem("encusedrbadmin", encryptedUser);
    return true;
  },
  getUserKeys: function (keys) {
    const userData = localStorage.getItem("encusedrbadmin");
    if (userData) {
      const bytes = CryptoJS.AES.decrypt(
        userData,
        process.env.REACT_APP_DATA_ENCRYPTION_KEY
      );
      let originalData = bytes.toString(CryptoJS.enc.Utf8);
      originalData = JSON.parse(originalData);
      let user = {};
      if (keys) {
        keys = keys.split(" ");
        for (const key in keys) {
          const keyV = keys[key];
          user[keyV] = originalData[keyV];
        }
      } else {
        user = originalData;
      }
      return user;
    }
    return {};
  },
};
