import React, { useContext } from "react";
import { Form, Dropdown } from "react-bootstrap";
import styles from "./styles.module.scss";
import SVG from "../../assets/SVG";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import context from "../../context/context";
import { useListenMetaMaskAccs } from "../../hooks/useListenMetamask";
const Navbar = ({ onSearch }) => {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const { showMenu, setShowMenu } = useContext(context);
  const onChange = (e) => {
    setSearch(e.target.value);
    onSearch(e.target.value);
  };
  useListenMetaMaskAccs()
  return (
    <div className={styles.navbarContainer}>
     
        <div className="search-bar d-flex align-items-center">
          <span
            className="menu-icon d-lg-none d-inline-block"
            onClick={() => setShowMenu(!showMenu)}
          >
            <SVG.MenuIcon />
          </span>
          {onSearch && (
            <>
          <SVG.SearchIcon />
          <Form.Control
            type="text"
            placeholder="Search"
            onChange={onChange}
            value={search}
          />
          </>)}
        </div>

      <div className="d-flex align-items-center">
        {/* <SVG.SupportNav className="me-20" />
        <span className="me-20 notification">
          <SVG.BellIcon />
        </span> */}
        <Dropdown className="user-menu">
          <Dropdown.Toggle id="user-dropdown">US</Dropdown.Toggle>

          <Dropdown.Menu>
            {/* <Dropdown.Item as="div">Profile</Dropdown.Item> */}
            {/* <Dropdown.Item as="div">Setting</Dropdown.Item> */}
            <Dropdown.Item
              as="div"
              onClick={() => {
                localStorage.removeItem("encusedrbadmin");
                navigate("/sign-in");
              }}
            >
              Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};
export default Navbar;
