import { ReactComponent as Logo } from "./images/logo.svg";
import { ReactComponent as DBIcon } from "./images/dashboardIcon.svg";
import { ReactComponent as SupportIcon } from "./images/support.svg";
import { ReactComponent as NFTIcon } from "./images/nftIcon.svg";
import { ReactComponent as SearchIcon } from "./images/search.svg";
import { ReactComponent as SupportNav } from "./images/supportNav.svg";
import { ReactComponent as BellIcon } from "./images/bell.svg";
import { ReactComponent as MuteIcon } from "./images/mute.svg";
import { ReactComponent as RemoveIcon } from "./images/remove.svg";
import { ReactComponent as MenuIcon } from "./images/menu.svg";
import { ReactComponent as CloseIcon } from "./images/close.svg";
import { ReactComponent as MetamaskIcon } from "./images/metamask.svg";
import { ReactComponent as MapLocation } from "./images/map-location.svg";
import { ReactComponent as Block } from "./images/block.svg";
import { ReactComponent as UnBlock } from "./images/unBlock.svg";
import { ReactComponent as Cart } from "./images/cart.svg";
import { ReactComponent as Orders } from "./images/orders.svg";
import { ReactComponent as Users } from "./images/users.svg";
import { ReactComponent as Edit } from "./images/edit.svg";

const svg = {
  Logo,
  DBIcon,
  SupportIcon,
  NFTIcon,
  SearchIcon,
  SupportNav,
  BellIcon,
  MuteIcon,
  RemoveIcon,
  MenuIcon,
  CloseIcon,
  MetamaskIcon,
  MapLocation,
  Block,
  UnBlock,
  Cart,
  Orders,
  Users,
  Edit
};

export default svg;
