import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import Navbar from "../../components/navbar/navbar";
import HeaderTitle from "../../components/headerTitle/headerTitle";
import styles from "./styles.module.scss";
import { handleRequest } from "../../utils/helpers";
import Button from "../../components/button/button";
import SVG from "../../assets/SVG";
import TableView from "../../components/table/table";

function Artist() {
  const [artist, setArtist] = useState({
    title: "",
    file: null,
  });
  const [artistsGallery, setArtistsGallery] = useState([]);
  const [error, setError] = useState(null);
  const [disableBtn, setDisableBtn] = useState(false);
  const tableHeadings = ["#", "Title", "Artist Image", "Action"];

  const converToTableData = (data) => {
    let tableData = [];
    data.length > 0 &&
      data.map((item, index) => {
        tableData.push([
          {
            item: index + 1,
          },
          {
            item: item?.title || "N/A",
          },
          {
            item: (
              <img
                src={`${process.env.REACT_APP_FILE_URL}${item.image}`}
                height={80}
                width={110}
                className="mt-1 mb-1"
                alt={item?.title}
              />
            ),
          },
          {
            item: (
              <span>
                <SVG.RemoveIcon
                  className="cursor-pointer"
                  onClick={() => removeArtist(item?._id)}
                />
              </span>
            ),
          },
        ]);
      });
    setArtistsGallery(tableData);
  };

  const handlePictureSelected = (event) => {
    var allowedExtensions = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/bmp",
      "image/svg+xml"
    ];
    var uploadPicture = event.target.files[0];
    if (!allowedExtensions.includes(uploadPicture.type)) {
      toast.error("Invalid file type");

      return false;
    }
    setArtist({ ...artist, file: event.target.files[0] });
  };

  const validation = () => {
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

    let errors = {};
    if (!artist.title) {
      errors = { ...errors, title: "This field is required" };
    }

    if (!artist.file) {
      errors = { ...errors, file: "Please upload the file" };
    }

    if (Object.keys(errors).length) {
      setError(errors);
      return false;
    }
    return true;
  };
  const onSubmit = async () => {
    if (!validation()) {
      return false;
    }
    setDisableBtn(true);
    var formData = new FormData();
    formData.append("title", artist.title);
    formData.append("file", artist.file);

    const response = await handleRequest("post", "/artist/add", formData);
    if (response.data.success) {
      toast.success(response.data.message);
      const file = document.querySelector("#file");
      getArtistGallery();
      file.value = "";
      setArtist({ title: "", file: null });
    }
    disableBtn(false);
  };

  const getArtistGallery = async () => {
    try {
      const result = await handleRequest("get", "/artist");
      if (result?.data?.length > 0) {
          converToTableData(result.data);
      }
    } catch (err) {
      console.log(err, "error=>");
    }
  };

  const removeArtist = async (id) => {
    try {
      const res = await handleRequest("delete", `/artist/${id}`);
      if (res.data.success) {
        getArtistGallery();
        toast.success(res.data.message);
      }
    } catch (err) {}
  };

  useEffect(() => {
    getArtistGallery();
  },[])

  return (
    <>
      <Navbar />
      <div className="d-flex justify-content-center px-lg-5 px-md-5 px-3 flex-column">
        <HeaderTitle title={"Artist"} />
        <Row className={styles.envForm}>
          <Col md={6}>
            <span className="d-flex align-items-center mb-2">
              <Form.Label className="mb-0" htmlFor="title">
                Title*:
              </Form.Label>
              {error?.title && <p className="error mb-0">{error?.title}</p>}
            </span>
            <Form.Control
              id="title"
              type="text"
              placeholder="Title"
              className="mb-15 no-arrows"
              name="title"
              value={artist.title}
              onChange={(e) => {
                setArtist({ ...artist, title: e.target.value });
                setError({ ...error, title: "" });
              }}
            />
          </Col>
          <Col md={6}>
            <span className="d-flex align-items-center mb-2">
              <Form.Label className="mb-0" htmlFor="URL">
                Upload File*:
              </Form.Label>
              {error?.file && <p className="error mb-0">{error.file}</p>}
            </span>

            <Form.Control
              id="file"
              type="file"
              className="mb-15 no-arrows"
              name="file"
              onChange={(e) => {
                handlePictureSelected(e);
                setError({ ...error, file: "" });
              }}
              // value={environment.file?.name}
            />
          </Col>
        </Row>
        <span>
          <Button
            text="Submit"
            className={"mt-3"}
            handler={onSubmit}
            disabled={disableBtn}
          />
        </span>
        {artistsGallery.length > 0 && (
          <div className="mt-5">
            <TableView
              tableHeadings={tableHeadings}
              tableData={artistsGallery}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default Artist;
