import styles from "./styles.module.scss";
import moment from "moment";
function SupportMessages({messages}) {

  return (
    <div className={styles.messageWrapper}>
      {messages &&
        messages.length > 0 &&
        messages.map((item) => {
          return (
            <div className="msg">
              <div className="d-flex align-items-center">
                {/* <img className="user-img" src={images.supportimg} alt="" /> */}
                <div>
                  <p className="user-name">{`${item.name} (${item?.email})`}</p>
                  <p className="msg-text">{item.description}</p>
                </div>
              </div>
              <div>
                <p className="date">
                  {moment(item.createdAt).format("D MMMM, YYYY")}
                </p>
                <p className="time">
                  {moment(item.createdAt, "HH:mm").format("HH:mm")}
                </p>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default SupportMessages;
